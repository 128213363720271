/** Dependencies */
import { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import moment from 'moment';
import bcrypt from 'bcryptjs-react';

/** Components */
import Loader from './../../components/Loader/Loader';

/** Images */
import EmpirikLogo from './../../assets/img/Logos-Empirik.svg';

/** Object class */
import wsUsers from './../../helpers/webservice/wsUsers.class';

/** Helpers */
import { isNullOrUndefined } from './../../helpers/functions';

/** SCSS */
import './../../index.scss';
import './AdminResetPassword.scss';

/** Smart Element Licence Key */
window.Smart.License = 'DEBCE491-9D1F-42D9-9529-671710B5FE06';

function AdminResetPassword()
{
  /** useDispatch hook **/
  const dispatch = useDispatch();

  /** Get params from url */
  const { resetPasswordToken } = useParams();

  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Init State */
  const [ userDatas, setUserDatas ] = useState( null );
  const [ password, setPassword ] = useState( null );
  
  const validForm = () => 
  {
    if( !isNullOrUndefined( password ) )
    {
      bcrypt.hash( password, 10, ( err, hashedPwd ) => 
      {
        if ( err )
          console.error( 'Hashing error', err );
        else 
        {
          new wsUsers(
            'reset-password-loader',
            dispatch,
            resetPasswordToken
          ).update( 
            userDatas.id,
            null, 
            null, 
            null, 
            null,
            hashedPwd,
            'null',
            'null',
            'setPasswordChanged'
          );
        }
      });
    }
  }

  useEffect( () => 
  {
    new wsUsers(
      'reset-password-loader',
      dispatch,
      resetPasswordToken
    ).get( 
      null,
      resetPasswordToken,
      'setUserDatas'
    );

  }, []);

  return(
    <div className='admin-reset-wrapper'>

      {/* Loader */}
      <Loader 
        loaderID={ 'reset-password-loader' }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setUserDatas: results => !isNullOrUndefined( results[0] ) ? setUserDatas( results[0] ) : void(0),
          setPasswordChanged: () => window.location = window.location.origin
        }}
      />

      {/*  Title */}
      <h1><img src={ EmpirikLogo } alt="Empirik - Impakkt" title="Empirik - Impakkt" width={ 200 } className='logo' /></h1>

      {/* Content */}
      {
        // Form
        !isNullOrUndefined( userDatas?.firstName )
        && !isNullOrUndefined( userDatas?.lastName )
        && !isNullOrUndefined( userDatas?.lastRequestPasswordDatetime ) ?
          <Fragment>
            <p>Bienvenue { !isNullOrUndefined( userDatas?.firstName ) ? userDatas?.firstName : '' } { !isNullOrUndefined( userDatas?.lastName ) ? userDatas?.lastName : '' }.</p>
            {
              !isNullOrUndefined( userDatas?.lastRequestPasswordDatetime )
              && moment( userDatas.lastRequestPasswordDatetime ).add( 4, 'h' ).isAfter( moment() ) ? 
                <Fragment>
                  <p>Merci de saisir votre mot de passe pour pouvoir accéder à l'outil Impakkt.</p>
                  <div className='form-container'>
                    <input 
                      type='password' 
                      placeholder='Saisir votre mot de passe'
                      onChange={ e => setPassword( e.target.value ) }
                      onKeyUp={ e => e.code === 'Enter' ? validForm() : null }
                    />
                    <button
                      type='button'
                      onClick={ validForm  }
                    >OK</button>
                  </div>
                </Fragment>
                : 'Votre demande d\'initilisation de mot de passe a expiré. Merci de contacter un administrateur pour renouveler la demande.'
            }
          </Fragment>

        // no request
        : <Fragment>
            <p>Aucune demande d'initialisation de mot de passe n'est en cours.</p>
          </Fragment>
        }
    </div>
  )
}

export default AdminResetPassword;