/** Dependencies */
import { useSearchParams } from "react-router-dom";
import ButtonGroup from "smart-webcomponents-react/buttongroup";

/** Helpers */
import { updateURLSearchParams } from './../../helpers/functions';

/** SCSS */
import './GranularitySelector.scss';

function GranularitySelector( props )
{
  const {
    currentPeriodGranularity,
    resetSearchParamOnclick
  } = props;

  /** Instance useSearchParams Hook */
  let [ searchParams, setSearchParams ] = useSearchParams();

  return(
    <div className="granularity-selector">
      <ButtonGroup
        className='column empty'
        dataSource={[
          { 
            label: 'Mois', 
            value: "month"
          },{ 
            label: 'Sem.', 
            value: "week"
          }
        ]}
        selectionMode='one'
        selectedValues={ 
          currentPeriodGranularity ? 
            [ currentPeriodGranularity ]
            : [ 'month' ]
        }
        onChange={ e => resetSearchParamOnclick ? 
          updateURLSearchParams({ 
            periodGranularity: e.detail.values[0]
          }, [ searchParams, setSearchParams ])
          : void( 0 ) 
        }
      />
    </div>
  )
}

export default GranularitySelector;