/** Dependencies */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

/** Components */
import Loader from './../../Loader/Loader';
import GridCoreAdminKeywords from "./../../Grid/Core/AdminKeywords/GridCoreAdminKeywords";

/** Helpers */
import { getInstanceDatas } from './../../../helpers/instance';
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { isNullOrUndefined } from '../../../helpers/functions';

function Keywords( props )
{
  const {
    callBackFct,
    reloadTrigger
  } = props;

  /** useDispatch hook **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Init State */
  const [ keywords, setKeywords ] = useState( null );
  const [ selectedKeywords, setSelectedKeywords ] = useState( null );
  const [ categories, setCategories ] = useState( null );
  const [ gscUrls, setGscUrls ] = useState( null );
  const [ datasLoaded, setDatasLoaded ] = useState( false );
  const [ gridComponent, setGridComponent ] = useState( null );

  /** Get instance infos from url */
  const { clientID, device, location } = useParams();

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Load Categories and GSC urls */
  useEffect( () => 
  {
    setDatasLoaded( false );

    // load existing keywords
    callWebservice(
      'admin-keywords-loader',
      'admin-keywords-loader',
      'get-keywords',
      dispatch,
      clientID,
      {
        where: {
          locations: [ location + '|' + city ],
          devices: [ device ]
        }
      },
      { function: 'setKeywords' }      
    );

    // load Categories
    callWebservice(
      'admin-keywords-loader',
      'admin-categories-loader',
      'get-categories',
      dispatch,
      clientID,
      {
        where: {
          locations: [ location + '|' + city ],
          devices: [ device ]
        }
      },
      { function: 'setCategories' }      
    );

    // load gsc url
    callWebservice(
      'admin-keywords-loader',
      'admin-gsc-urls-loader',
      'gsc',
      dispatch,
      clientID,
      {
        cols: ["DISTINCT url"],
        where: {
          locations: [ location ],
          devices: [ device ]
        }
      },
      { function: 'setGscUrls' }
    );
    
  }, [
    clientID,
    device,
    location,
    reloadTrigger
  ]);

  useEffect( () => 
  {
    if( 
      !isNullOrUndefined( keywords ) 
      && !isNullOrUndefined( categories ) 
      && !isNullOrUndefined( gscUrls ) 
    )
      callBackFct({
        keywords: keywords,
        selectedKeywords: selectedKeywords,
        categories: categories,
        gscUrls: gscUrls
      })

  }, [
    JSON.stringify( keywords ),
    JSON.stringify( selectedKeywords ),
    JSON.stringify( categories ),
    JSON.stringify( gscUrls )
  ]);

  useEffect( () => 
  {
    if( datasLoaded )
      setGridComponent(
        <GridCoreAdminKeywords
          loadingParams={{
            clientID: clientID,
            device: device,
            location: location + '|' + city,
          }}
          categories={ categories }
          gscUrls={ gscUrls }
          reloadTrigger={ reloadTrigger }
          callBackFct={ results => 
          {
            if( !results.gridComponentLoading )
            {
              setSelectedKeywords( results.selectedKeywords );
              setKeywords( results.nonFilteredDataSource.map( elem => elem.label ) );
            }
          }}
        />
      );

  }, [ datasLoaded ]);
  
  return(
    <div>

      {/* Loader */}
      <Loader 
        loaderID={ 'admin-keywords-loader' }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setKeywords: results => setKeywords( results.map( keyword => keyword.keyword ) ),
          setCategories: results => setCategories( results ),
          setGscUrls: results => setGscUrls( results.map( url => url.url ) )
        }}
        globalCallBack={ () => setDatasLoaded( true ) } 
      />

      {/* Grid */}
      { gridComponent }

    </div>
  )
}

export default Keywords;