/** Dependencies */
import { useEffect, useState } from 'react';

/** Components */
import DropDownMenuMultiFilter from './../../Filters/DropDownMenuMulti';

/** Helpers */
import { deepEqual, isNullOrUndefined } from './../../../helpers/functions';

/** SCSS */
import './DropDownMenuMulti.scss';

function DropDownMenuMulti( props )
{
  const {
    id,
    pictoTitle,
    filterLabel,
    dropDownMenuValues,
    initFilterValues,
    callBackFunction
  } = props;

  /** Init State */
  const [ filterValues, setFilterValues ] = useState( initFilterValues !== null ? initFilterValues : null );

  /** Update DropDown filter values */
  useEffect( () => 
  {
    // callback function if filter values not null
    if( 
      !isNullOrUndefined( filterValues )
      && typeof callBackFunction === 'function' 
      && !deepEqual( filterValues, initFilterValues )
    )
      callBackFunction( filterValues );

  }, [ JSON.stringify( filterValues ) ] )

  return(
    <div className='filter-container dropdown'>

      {/* Title */}
      <h4 className='picto-title'>
        { pictoTitle }
        { filterLabel }
      </h4>

      {/* Filter drop down menu on status */}
      { dropDownMenuValues !== null && dropDownMenuValues.length > 0 ?
        <DropDownMenuMultiFilter
          id={ id + '-dropdown-multi' }
          label={ filterLabel }
          dropDownMenuValues={ dropDownMenuValues }
          initCurrentValues={ !isNullOrUndefined( initFilterValues?.value?.values ) ? initFilterValues.value.values : null }
          callBackFunction={ values => setFilterValues({ 
            ...filterValues, 
            value: { values: values }
          })}
        />
        : null
      }

    </div>
  )
}

export default DropDownMenuMulti;