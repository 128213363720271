/** Dependencies */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import moment from 'moment';

/** Components */
import InstanceInfos from './../../components/Instances/InstanceInfos';
import GridCoreEditorCalendar from './../../components/Grid/Core/EditorCalendar/GridCoreEditorCalendar';
import Loader from './../../components/Loader/Loader';

/** Helpers */
import { getInstanceDatas } from './../../helpers/instance';
import { isNullOrUndefined } from './../../helpers/functions';

/** Object class */
import wsUsers from './../../helpers/webservice/wsUsers.class';

/** SCSS */
import './EditoCalendar.scss';

function EditoCalendar()
{
  /** useDispatch hook **/
	const dispatch = useDispatch();

  // Define colors
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Get instance infos from url */
  const { clientID, location } = useParams();

  /** Init State */
  const [ users, setUsers ] = useState( null );
  const [ gridComponent, setGridcomponent ] = useState( null );

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;
  
  /** Load datas */
  useEffect( () =>
  {
    // load users
    new wsUsers(
      'edito-calendar-loader',
      dispatch,
      clientID
    ).get( 
      null,
      null,
      'setUsers'
    );

  }, [ 
    clientID,
    location 
  ]);

  useEffect( () => 
  {
    if( !isNullOrUndefined( users ) )
      setGridcomponent( 
        <GridCoreEditorCalendar               
          loadingParams={{
            clientID: clientID,
            location: location + '|' + city,
            currentPeriod: { 
              startDate: moment().startOf( 'month' ).subtract( 11, 'month' ).format( 'YMMDD' ), 
              endDate: moment().endOf( 'month' ).format( 'YMMDD' )
            }
          }}
          users={ users }
        /> 
      );

  }, [ users ] );

  return(
    <div className='edito-calendar'>

      <div className='box1'>
        <InstanceInfos deviceButtonsDisabled />
      </div>

      <div className='box2'>
        <div className='header'>            
          <div className='header-left-container'>
            <h3>Calendrier Éditorial</h3>
          </div>          
        </div>        
      </div>

      <div className='box3'> 

        {/* Loader */}
        <Loader 
          loaderID={ 'edito-calendar-loader' }
          loaderStyle={{
            width:'25', 
            stroke: blueColor, 
            viewBox:'-2 -2 42 42'
          }}
          callBackFcts={{
            setUsers: results => setUsers( results )
          }}
        />

        {/* Grid */}
        { !isNullOrUndefined( gridComponent ) ? gridComponent : null }

      </div>
      
    </div>
  )
}

export default EditoCalendar;