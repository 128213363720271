/** Dependencies */
import { useState } from 'react';
import { Chart } from 'smart-webcomponents-react/chart';

/** Helpers */
import { getMinMaxFromArrayObject } from './../../helpers/functions';

/** SCSS */
import './InsightsCompareColumnChart.scss';

function InsightsCompareColumnChart( props )
{
  const {
    id,
    datas,
    dataField,
    transparent,
    xAxisLabelVisible
  } = props;

  /** UseState Hook */
  const [ chartWidth, setChartWidth ] = useState( null );
  
  // Define positive and negative colors
  const gridLinesColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey3' ).trim();
  const compareSerieColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();
  const positiveColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-orange' ).trim();
  const negativeColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  // get min / max values from compare and current datas
  const minMaxValues = getMinMaxFromArrayObject( datas, ['current', 'compare'] );

  // Define settings for chart
  const settings = {
    caption: '',
    description: '',
    showLegend: false,
    animation: 'none',
    showBorderLine: false,
    showToolTips: true,
    backgroundColor: 'white',
    columnSeriesOverlap: true,
    toolTipFormatFunction: ( value, itemIndex, serie, group, xAxisValue, xAxis ) => 
      ( serie.dataField === 'current' ? xAxisValue : datas[itemIndex].compareXaxisCaption ) 
      + ' : ' 
      +  Intl.NumberFormat('fr-FR').format( value ),
    padding: {
      left: 0, 
      top: 5,
      right: -1,
      bottom: 5
    },
    titlePadding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    dataSource: datas,
    xAxis: {
      dataField: dataField,
      tickMarks: {
        visible: false
      },
      labels: {
        visible: xAxisLabelVisible !== undefined ? xAxisLabelVisible : true,
        class: 'x-axis-labels',
        offset: {
          y: 5
        }
      },
      gridLines: {
        color: gridLinesColor
      },
      valuesOnTicks: false
    },
    seriesGroups: [      
      {
        type: 'column',
        columnsGapPercent: 35,
        valueAxis: {
          visible: true,
          axisSize: 30,
          minValue: 0,
          maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max : 100,
          gridLines: {
            dashStyle: '4,4',
            color: gridLinesColor
          },
          tickMarks: {
            visible: false
          },
          labels: {
            class: 'values-axis-labels'
          },
          formatFunction: value => value >= 0 ? Intl.NumberFormat('fr-FR', { notation: 'compact' }).format( value ) : '',
        },
        series:[
          { 
            dataField: 'compare',
            fillColor: compareSerieColor,
            fillColorSelected: compareSerieColor,
            lineColor: compareSerieColor,
            lineColorSelected: compareSerieColor,
            lineWidth: 0,
            opacity: 0.25
          }           
        ]
      },
      {
        type: 'column',
        columnsGapPercent: 35,
        valueAxis: {
          visible: false,
          minValue: 0,
          maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max : 100
        },
        series:[
          { 
            dataField: 'current',
            lineWidth: transparent === true ? 2 : 0,
            opacity: 0.8,
            colorFunction: ( value, itemIndex ) => 
            {
              // set color
              let fillColor = positiveColor;
              if( datas.length > 1 )
              {
                if( itemIndex === 0 && value < datas[1].current ) {
                  fillColor = positiveColor;
                } else if( itemIndex === 0 && value > datas[1].current ) {
                  fillColor = negativeColor;
                } else if ( itemIndex > 0 && datas[itemIndex - 1] && value < datas[itemIndex - 1].current ) {
                  fillColor = positiveColor;
                } else if ( itemIndex > 0 && datas[itemIndex - 1] && value > datas[itemIndex - 1].current ) {
                  fillColor = negativeColor;
                }                
              }

              // set return 
              if( transparent === true )
                return ({ 
                  fillColor: 'white', 
                  lineColor: fillColor,
                  lineColorSelected: fillColor 
                });
              else
                return ({ 
                  fillColor: fillColor, 
                  fillColorSelected: fillColor, 
                  lineColor: fillColor 
                });
            }  
          }
        ]
      }      
    ]
  };

  return(
    <div 
      className={ 
        chartWidth > 1000 && datas.length > 24 ? 
          'insights-bar-chart small' 
        : chartWidth > 1000 && datas.length > 12 && datas.length <= 24 ? 
          'insights-bar-chart medium' 
        : chartWidth > 1000 && datas.length <= 12 ? 
          'insights-bar-chart large' 
        : 'insights-bar-chart' 
      }
    >
      <Chart
				id = { id + '-chart' }
        onRefreshEnd={ e => setChartWidth( e.detail.instance.clientWidth ) }
        {...settings}
			/>
    </div>
  )
}

export default InsightsCompareColumnChart;