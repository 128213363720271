/** Dependencies */
import { useDispatch } from 'react-redux';
import moment from 'moment';

/** Components */
import GridCore from './../GridCore.js';
import GridCellRemove from './../../Cells/GridCellRemove';
import GridCellDropDownMenu from './../../Cells/GridCellDropDownMenu';
import GridCellPicTextPicLink from './../../Cells/GridCellPicTextPicLink';

/** Helpers */
import { getUpdateOrAutoValue } from './../../../../helpers/datas.js';
import { buildDropDownFilterPanel } from './../../../../helpers/filters';
import { 
  isNullOrUndefined,
  getUrlPart,
  isValidJSON,
  filterObjectOnKeys,
  deepMergeObj
} from './../../../../helpers/functions.js';

/** Services */
import { getItem } from './../../../../services/LocaleStorage.js';

/** Object class */
import wsKeywords from './../../../../helpers/webservice/wsKeywords.class';
import wsExpectedUrl from './../../../../helpers/webservice/wsExpectedUrl.class';

/** SCSS */
import './GridCoreAdminKeywords.scss';

function GridCoreAdminKeywords( props )
{
  /** Get props */
  const {
    loadingParams,
    categories,
    gscUrls,
    reloadTrigger,
    callBackFct
  } = props;

  /** Get user id */
  const userId = getItem( 'userId' );

  /** useDispatch hook **/
	const dispatch = useDispatch();

  /** Define page size */
  const pageSize = 15;

  /** Define data fields */
  const dataFields = [
    { name: 'dateAjout', dataType: 'date' },
    { name: 'label', dataType: 'string' },
    { name: 'expectedUrl', dataType: 'string' },
    { name: 'cat1', dataType: 'string' },
    { name: 'cat2', dataType: 'string' },
    { name: 'cat3', dataType: 'string' },  
    { name: 'remove', dataType: 'string' }
  ];

  /** Define data fields to load */
  const dataFieldsToLoad = [ 
    'keywords.followedKeywords',
    'keywords.expectedUrl',
    'serpHtmlUrl'  
  ];

  /** Define grid columns properties */
  const gridColumns = [
    { 
      label: 'Date ajout',
      dataField: 'dateAjout',
      cellsFormat: 'dd/MM/yyyy',
      width: 120,
      allowEdit: false
    },{ 
      label: 'Mots-clés', 
      dataField: 'label',
      align: 'left',
      allowHide: false,
      allowEdit: false,
      cellsClassName: 'keywords'
    },{ 
      label: 'URL assignée', 
      dataField: 'expectedUrl',
      align: 'left',
      width: 400,
      template: formatObject => 
      {
        formatObject.template = GridCellPicTextPicLink( !isNullOrUndefined( formatObject.value ) ? 
          { 
            text: getUrlPart( formatObject.value, 'pathname' ),
            textLink: formatObject.value
          }
          : null 
        );
      },
      editor: {
        template: '<smart-input></smart-input>',
        settings: {
          id: 'admin-expected-url'
        },
        onInit( row, column, editor )
        {
          // get input element
          const input = editor.firstElementChild;

          // set gsc urls to dataSource input element
          input.dataSource = gscUrls;     
        },
        getValue: ( value ) => value
      }
    },{ 
      label: 'Catégorie', 
      dataField: 'cat1', 
      align: 'left',
      width: 200,
      allowEdit: false,
      cellsWrap: true,
      template: formatObject => 
      {
        formatObject.template = GridCellDropDownMenu({
          dataSource: isValidJSON( formatObject.value ) ?
            JSON.parse( formatObject.value ).categories1
            : [],
          currentValue: isValidJSON( formatObject.value ) ?
            {
              label: JSON.parse( formatObject.value ).cat1,
              id: JSON.parse( formatObject.value ).cat1Id
            }
            : null,
          placeHolder: 'Catégorie',
          callBackFct: ( result ) => updateCategories( 
            formatObject.row.data.label, 
            [ result, 'null', 'null' ], 
            isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).loadingParams
              : null 
          )
        });
      },
      updateFilterPanel: () => {},
      getFilterPanel: column => 
      {
        // get categories list
        const categoriesList = Object.keys( categories );

        // build panel and return value
        return buildDropDownFilterPanel( categoriesList, column, onFilter );
      }
    },{ 
      label: 'Sous catégorie', 
      dataField: 'cat2', 
      align: 'left',
      width: 200,
      allowEdit: false,
      cellsWrap: true,
      template: formatObject => 
      {
        formatObject.template = GridCellDropDownMenu({
          dataSource: isValidJSON( formatObject.value ) ?
            JSON.parse( formatObject.value ).categories2
            : [],
          currentValue: isValidJSON( formatObject.value ) ?
            {
              label: JSON.parse( formatObject.value ).cat2,
              id: JSON.parse( formatObject.value ).cat2Id
            }
            : null,
          placeHolder: 'Sous catégorie',
          callBackFct: ( result ) => updateCategories(
            formatObject.row.data.label, 
            [ JSON.parse( formatObject.row.data.cat1 ).cat1Id, result, 'null' ], 
            isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).loadingParams
              : null  
          )
        });
      },
      updateFilterPanel: ( filterPanel, column ) => 
      {
        if( isNullOrUndefined( column?.filter?.filters ) )
        {
          // current selected cat1
          const currentSelectedCat1 = !isNullOrUndefined( column?.grid?.columnByDataField?.cat1?.filter?.filters ) ? 
          column.grid.columnByDataField.cat1.filter.filters.map( elem => elem.value.replaceAll( '##', '' ) )
          : Object.keys( categories );

          // filter categories with selected cat1
          const currentCategories = filterObjectOnKeys( categories, currentSelectedCat1 );

          // get categories list
          const categoriesList = [...new Set( 
          Object.values( currentCategories ).map( elem => 
            Object.keys( elem.cat2 ) 
          ).flat() 
          )];

          // update filter panel
          filterPanel.dataSource = categoriesList.map( elem => ({ label: elem, value: elem }));
          filterPanel.onFilter = () => onFilter( column, filterPanel, categoriesList );
        }        
      },
      getFilterPanel: column => 
      {
        // current selected cat1
        const currentSelectedCat1 = !isNullOrUndefined( column?.grid?.columnByDataField?.cat1?.filter?.filters ) ? 
          column.grid.columnByDataField.cat1.filter.filters.map( elem => elem.value.replaceAll( '##', '' ) )
          : Object.keys( categories );

        // filter categories with selected cat1
        const currentCategories = filterObjectOnKeys( categories, currentSelectedCat1 );
        
        // get categories list
        const categoriesList = [...new Set( 
          Object.values( currentCategories ).map( elem => 
            Object.keys( elem.cat2 ) 
          ).flat() 
        )];

        // build panel and return value
        return buildDropDownFilterPanel( categoriesList, column, onFilter );
      }
    },{ 
      label: 'Sous sous catégorie', 
      dataField: 'cat3', 
      align: 'left',
      width: 200,
      allowEdit: false,
      cellsWrap: true,
      template: formatObject => 
      {
        formatObject.template = GridCellDropDownMenu({
          dataSource: isValidJSON( formatObject.value ) ?
            JSON.parse( formatObject.value ).categories3
            : [],
          currentValue: isValidJSON( formatObject.value ) ?
            {
              label: JSON.parse( formatObject.value ).cat3,
              id: JSON.parse( formatObject.value ).cat3Id
            }
            : null,
          placeHolder: 'Sous sous catégorie',
          callBackFct: ( result ) => updateCategories( 
            formatObject.row.data.label, 
            [ JSON.parse( formatObject.row.data.cat1 ).cat1Id, JSON.parse( formatObject.row.data.cat2 ).cat2Id, result ], 
            isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).loadingParams
              : null  
          )
        });
      },
      updateFilterPanel: ( filterPanel, column ) => 
      {
        if( isNullOrUndefined( column?.filter?.filters ) )
        {
          // current selected cat1
          const currentSelectedCat1 = !isNullOrUndefined( column?.grid?.columnByDataField?.cat1?.filter?.filters ) ? 
            column.grid.columnByDataField.cat1.filter.filters.map( elem => elem.value.replaceAll( '##', '' ) )
            : Object.keys( categories );

          // filter categories with selected cat1
          let currentCategories = filterObjectOnKeys( categories, currentSelectedCat1 );

          // current selected cat2
          const currentSelectedCat2 = !isNullOrUndefined( column?.grid?.columnByDataField?.cat2?.filter?.filters ) ? 
            column.grid.columnByDataField.cat2.filter.filters.map( elem => elem.value.replaceAll( '##', '' ) )
            : Object.keys( deepMergeObj( ...Object.values( categories ).map( elem => elem.cat2 ) ) );
                  
          // filter current categories with selected cat2
          currentCategories = filterObjectOnKeys( deepMergeObj( ...Object.values( currentCategories ).map( elem => elem.cat2 ) ), currentSelectedCat2 );

          // get categories list
          const categoriesList = [...new Set( 
            Object.values( currentCategories ).map( elem => 
              Object.keys( elem.cat3 ) 
            ).flat()            
          )];

          // update filter panel
          filterPanel.dataSource = categoriesList.map( elem => ({ label: elem, value: elem }));
          filterPanel.onFilter = () => onFilter( column, filterPanel, categoriesList );
        }
      },
      getFilterPanel: column => 
      {
        // current selected cat1
        const currentSelectedCat1 = !isNullOrUndefined( column?.grid?.columnByDataField?.cat1?.filter?.filters ) ? 
          column.grid.columnByDataField.cat1.filter.filters.map( elem => elem.value.replaceAll( '##', '' ) )
          : Object.keys( categories );

        // filter categories with selected cat1
        let currentCategories = filterObjectOnKeys( categories, currentSelectedCat1 );

        // current selected cat2
        const currentSelectedCat2 = !isNullOrUndefined( column?.grid?.columnByDataField?.cat2?.filter?.filters ) ? 
          column.grid.columnByDataField.cat2.filter.filters.map( elem => elem.value.replaceAll( '##', '' ) )
          : Object.keys( deepMergeObj( ...Object.values( categories ).map( elem => elem.cat2 ) ) );
                
        // filter current categories with selected cat2
        currentCategories = filterObjectOnKeys( deepMergeObj( ...Object.values( currentCategories ).map( elem => elem.cat2 ) ), currentSelectedCat2 );

        // get categories list
        const categoriesList = [...new Set( 
          Object.values( currentCategories ).map( elem => 
            Object.keys( elem.cat3 ) 
          ).flat()            
        )];

        // build panel and return value
        return buildDropDownFilterPanel( categoriesList, column, onFilter );
      }
    },{ 
      label: '', 
      dataField: 'remove',
      align: 'left',
      width: 50,
      allowEdit: false,
      allowSort: false,
      allowFilter: false,
      cellsWrap: true,
      showActionButton: false,
      template: formatObject => 
      {
        formatObject.template = GridCellRemove({
          index: formatObject.row.visibleIndex,
          pageSize: pageSize,
          areYouSureText: isValidJSON( formatObject.value ) ?
            'Êtes vous sûr de vouloir supprimer le mot-clé "' + JSON.parse( formatObject.value ).keyword + '" ?'
            : null,
          confirmCallBackFct: () => removeKeyword( 
            isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).keyword
              : null,
            isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).loadingParams
              : null 
          )
        });
      }
    }
  ];

  /** Format data source for Grid component */
  const formatDataSourceFct = response => response.map( elem => ({ 

    // date ajout
    dateAjout: moment( elem.createdDate, 'YYYYMMDD' ).format( 'YYYY-MM-DD' ),

    // label
    label: elem.label,

    // keywords
    keywords: [ elem.label ],

    // expected url
    expectedUrl: getUpdateOrAutoValue( elem.expectedUrl, 'expectedUrl' ),

    // categorie
    cat1: JSON.stringify({ 
      cat1: elem.categories.category1, 
      cat1Id: elem.categories.category1Id, 
      cat1filter: '##' + elem.categories.category1 + '##', 
      categories1: !isNullOrUndefined( categories ) ?
        Object.keys( categories ).map( category => ({
          label: category,
          id: categories[category].id
        }))
        : null,
      loadingParams: loadingParams 
    }),

    // sub categorie
    cat2: JSON.stringify({ 
      cat2: elem.categories.category2, 
      cat2Id: elem.categories.category2Id, 
      cat2filter: '##' + elem.categories.category2 + '##', 
      categories2: !isNullOrUndefined( categories[elem?.categories?.category1]?.cat2 ) ? 
        Object.keys( categories[elem.categories.category1].cat2 ).map( category => ({
          label: category,
          id: categories[elem.categories.category1].cat2[category].id
        }))
        : null,
      loadingParams: loadingParams 
    }),

    // sub sub categorie
    cat3: JSON.stringify({ 
      cat3: elem.categories.category3, 
      cat3Id: elem.categories.category3Id, 
      cat3filter: '##' + elem.categories.category3 + '##', 
      categories3: !isNullOrUndefined( categories[elem?.categories?.category1]?.cat2[elem?.categories?.category2]?.cat3 ) ? 
        Object.keys( categories[elem.categories.category1].cat2[elem.categories.category2].cat3 ).map( category => ({
          label: category,
          id: categories[elem.categories.category1].cat2[elem.categories.category2].cat3[category].id
        })) 
        : null,
      loadingParams: loadingParams 
    }),

    // remove
    remove: JSON.stringify({ 
      keyword: elem.label,
      loadingParams: loadingParams 
    })
  }));

  const onFilter = ( column, panel, itemsList ) => 
  {
    // get selected indexes
    const selectedIndexes = panel.querySelector( 'smart-tree' ).selectedIndexes
      .map( elem => parseInt( elem.split( '.' )[1] ) )
      .filter( elem => !isNullOrUndefined( elem ));

    // get selected values          
    const selectedValues = itemsList.filter( ( elem, index ) => 
      selectedIndexes.includes( index ) 
    ).map( elem => 'contains "##' + elem + '##"' );

    // set column filter
    column.filter = selectedValues.join( ' or ' );

    // reset child column
    // get current category id
    const currentCatId = parseInt( column.dataField.slice( -1 ) );
    if( currentCatId === 1 )
    {
      column.grid.columnByDataField.cat2.filter = null
      column.grid.columnByDataField.cat3.filter = null

    } else if ( currentCatId === 2 )
      column.grid.columnByDataField.cat3.filter = null 
  }

  const updateExpectedUrl = ( keyword, expectedUrl ) => 
  {
    new wsExpectedUrl(
      'grid-loader-admin-keywords',
      loadingParams.device,
      loadingParams.location,
      dispatch,
      loadingParams.clientID
    ).addExpectedUrl( 
      [ keyword ],
      expectedUrl,
      'otherurl',
      userId
    );
  }

  const updateCategories = ( keyword, categories, loadingParams ) => 
  {
    new wsKeywords(
      'grid-loader-admin-keywords',
      loadingParams.device,
      loadingParams.location,
      dispatch,
      loadingParams.clientID
    ).update( 
      [ keyword ], 
      null,
      categories,
      null,
      'reloadGrid'
    );
  }

  const removeKeyword = ( keyword, loadingParams ) => 
  {
    new wsKeywords(
      'grid-loader-admin-keywords',
      loadingParams.device,
      loadingParams.location,
      dispatch,
      loadingParams.clientID
    ).remove( 
      [ keyword ],
      'reloadGrid'
    );
  }
  
  return(
    <GridCore
      coreType='admin-keywords'
      gridColumns={ gridColumns }
      dataFields={ dataFields }
      loadingParams={{
        clientID: loadingParams.clientID,
        where: {
          locations: [ loadingParams.location ],
          devices: [ loadingParams.device ]
        },
        dataFieldsToLoad: dataFieldsToLoad
      }}
      displayMode='full-grid'
      formatDataSourceFct={ formatDataSourceFct }
      pageSize={ pageSize }
      filtering={ true }
      editing={ true }
      checkboxesSelection={ true }
      columnHeight={ 72 }
      pagerHeight={ 50 }
      onEndEditFct={ e => 
      {
        if( 
          !isNullOrUndefined(  e.detail.cell.value )
          && !isNullOrUndefined(  e.detail.cell.oldValue )
          && e.detail.cell.value !== e.detail.cell.oldValue 
        ){
          // choose action to call with datafield updated name
          switch ( e.detail.dataField ) 
          {
            case 'expectedUrl':
              updateExpectedUrl( e.detail.data.label, e.detail.cell.value );    
              break;

            default: void(0); 
              break;
          }
          
          // Update cell value with new label
          e.detail.cell.oldValue = e.detail.cell.value;
        }
      }}
      callBackFct={ results => typeof callBackFct === 'function' ? 
        callBackFct( results ) 
        : null
      }
      reloadTrigger={ reloadTrigger }
    />
  );
}

export default GridCoreAdminKeywords;