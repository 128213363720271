/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";

/** Components */
import GridCore from '../GridCore.js';
import GridCellPicTextPicLink from './../../Cells/GridCellPicTextPicLink';
import GridCellCompareValues from './../../Cells/GridCellCompareValues';
import GridCellPictos from './../../Cells/GridCellPictos';
import GridCellStatusAction from './../../Cells/GridCellStatusAction';

/** Helpers */
import { sortByWithNullValue } from './../../../../helpers/sort.js';
import { getUpdateOrAutoValue } from './../../../../helpers/datas.js';
import { updateFilterDatas } from './../../../../helpers/filters.js';
import { updateURLSearchParams } from './../../../../helpers/functions';
import { getDateFromPeriod } from './../../../../helpers/dates.js';
import { 
  getMinMaxFromArrayObject, 
  isValidJSON, 
  isNullOrUndefined,
  getUrlPart
} from './../../../../helpers/functions.js';

/** SCSS */
import './GridCoreKeywords.scss';

function GridCoreKeywords( props )
{
  /** Get props */
  const {
    displayMode,
    loadingParams,
    gridSelectedKeywordsGroups,
    callBackFunction,
    reloadTriggerCallBackFct,
    reloadTrigger
  } = props;

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Init State */
  const [ dataSource, setDataSource ] = useState( null );
  const [ callBackParams, setCallBackParams ] = useState( null );

  /** UseSearchParams Hook */ 
  const [ searchParams, setSearchParams ] = useSearchParams();
  
  /** Define page size */
  const pageSize = 25;

  /** Define data fields */
  const dataFields = [
    { name: 'subitems_toggler', dataType: 'boolean' },
    { name: 'label', dataType: 'string' },
    { name: 'status_action', map: 'status_action', dataType: 'string' },
    { name: 'gsc_urls', map: 'gsc.urls', dataType: 'string' },
    { name: 'volume_value', map: 'volume.value', dataType: 'number' },
    { name: 'volume_variation', map: 'volume.variation', dataType: 'number' },
    { name: 'impressions_value', map: 'gsc.impressions.value', dataType: 'number' },
    { name: 'impressions_variation', map: 'gsc.impressions.variation', dataType: 'number' },
    { name: 'clicks_value', map: 'gsc.clicks.value', dataType: 'number' },
    { name: 'clicks_variation', map: 'gsc.clicks.variation', dataType: 'number' },
    { name: 'ctr_value', map: 'gsc.ctr.value', dataType: 'number' },
    { name: 'ctr_variation', map: 'gsc.ctr.variation', dataType: 'number' },
    { name: 'position_value', map: 'serp.position.value', dataType: 'number' },
    { name: 'position_variation', map: 'serp.position.variation', dataType: 'number' },
    { name: 'rate_value', map: 'serp.rate.value', dataType: 'number' },
    { name: 'rate_variation', map: 'serp.rate.variation', dataType: 'number' },
    { name: 'snippets', map: 'serp.snippets', dataType: 'string' }
  ];

  /** Define data fields to load */
  const dataFieldsToLoad = [ 
    'keywords.followedKeywords',
    'keywords.gsc',
    'keywords.groups',
    'keywords.status',
    'keywords.expectedUrl',
    'serpHtmlUrl',
    'volume',
    'gsc.impressions',
    'gsc.clicks',
    'gsc.urls',
    'serp.position',    
    'serp.rate',
    'serp.snippets',
    'serp.urls'
  ];

  /** Define grid columns properties */
  const gridColumns = [

    // fake column to have subitems toggler
    { 
      label: 'Bouton ouverture accordéons',
      className: 'hidden',
      dataField: 'subitems_toggler',
      width: 40,
      visible: true,
      allowHide: false,
      allowSort: false,
      template: formatObject => 
      {
        formatObject.template = formatObject.value ? 
          '<div class="subitems-toggler-container"><button toggle-button="" class="subitems-toggler"></button></div>'
          : ' '
      }
    },{ 
      label: 'Mots-clés', 
      dataField: 'label',
      align: 'left',
      allowHide: false,
      cellsClassName: 'keywords',
      template: formatObject => 
      {
        if( isValidJSON( formatObject.value ) )
        {
          /** Define on click function to filter on label */
          let currentValues = {};
          let currentFilters = !isNullOrUndefined( JSON.parse( formatObject.value ).currentFilters ) ? 
            JSON.parse( JSON.parse( formatObject.value ).currentFilters )
            : [];
         
          // with existing filters
          if( currentFilters.length > 0  )
          {
            // get current label filter params
            const labelFilterIndex = currentFilters.findIndex( elem => elem.filterName === 'label' );
  
            if( labelFilterIndex > -1 )
            {
              currentValues = {
                ...currentFilters[labelFilterIndex].values,
                value: {
                  value: JSON.parse( formatObject.value ).value,
                  filterStringType: "equalsIgnoreCase"
                }
              }            
            }
          
          // without existing filters
          } else {
  
            currentValues = { 
              value: {
                value: JSON.parse( formatObject.value ).value,
                filterStringType: "equalsIgnoreCase"
              }
            };
          }
  
          // build filter url param
          const filterUrlParam = updateFilterDatas( 
            currentFilters,
            { value: 'string', keywordsGroups: 'keywords-groups' },
            'label',
            'label',
            'Mot-clé',
            currentValues
          );
  
          // apply template
          formatObject.template = GridCellPicTextPicLink( formatObject.value !== null ? 
            { 
              text: JSON.parse( formatObject.value ).value,
              onClickFct: () => updateURLSearchParams( { filters: JSON.stringify( filterUrlParam ) }, [ searchParams, setSearchParams ] ),
              postPic: JSON.parse( formatObject.value ).link !== undefined ? 'GoogleLink' : undefined,
              postPicOptions: JSON.parse( formatObject.value ).link !== undefined ? { width: 24, height: 24 } : undefined,
              postPicLink: JSON.parse( formatObject.value ).link
            }
            : null 
          );
        }
      }
    },{ 
      label: 'URL', 
      dataField: 'gsc_urls',
      align: 'left',
      showDescriptionButton: true, 
      description: 'URLs positionnées sur le mot-clé. Picto ! sur fond rouge : URL attendue = Valeur de l’URL',
      template: formatObject => {
        formatObject.template = GridCellPicTextPicLink( formatObject !== null ? 
          { 
            text: Array.isArray( JSON.parse( formatObject.value ).value ) ? 
              getUrlPart( JSON.parse( formatObject.value ).value[0].url, 'pathname' )
              : undefined,
            textLink: Array.isArray( JSON.parse( formatObject.value ).value ) ? 
              JSON.parse( formatObject.value ).value[0].url 
              : undefined,
            postToggleButton: (
                Array.isArray( JSON.parse( formatObject.value ).value ) 
                && !isNullOrUndefined( JSON.parse( formatObject.value ).value[0].nbUrls ) 
                && JSON.parse( formatObject.value ).value[0].nbUrls > 1 
              ) ? 
              true
              : undefined,
            postToggleButtonValue: (
                Array.isArray( JSON.parse( formatObject.value ).value ) 
                && !isNullOrUndefined( JSON.parse( formatObject.value ).value[0].nbUrls ) 
                && JSON.parse( formatObject.value ).value[0].nbUrls > 1 
              ) ? 
              '+' + ( JSON.parse( formatObject.value ).value[0].nbUrls - 1 )
              : undefined,
            postPic: Array.isArray( JSON.parse( formatObject.value ).value ) && JSON.parse( formatObject.value ).value[0].isExpectedUrl === false ? 
              'ExclamationMark' 
              : undefined,                
            postPicOptions: Array.isArray( JSON.parse( formatObject.value ).value ) && JSON.parse( formatObject.value ).value[0].isExpectedUrl === false ? 
              { width: 16, height: 16, fill: 'white', title: JSON.parse( formatObject.value ).value[0].expectedUrl } 
              : undefined
          }
          : null 
        );
      }
    },{ 
      label: 'Clics', 
      dataField: 'clicks_value', 
      align: 'center',
      width: 70,
      sortOrder: 'desc',
      showDescriptionButton: true, 
      description: 'Clics sur vos positions SEO Google',
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 )
    },{ 
      label: '', 
      dataField: 'clicks_variation', 
      showIcon: true,
      align: 'center',
      width: 60,      
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Pos. SERP', 
      dataField: 'position_value', 
      align: 'center',
      width: 70,
      showDescriptionButton: true, 
      description: 'Position moyenne calculée sur le dernier mois de la période sélectionnée',
      template: formatObject => {
        formatObject.template = formatObject.value !== -1 ?
          GridCellCompareValues({ 
            value: formatObject.value, 
            variation: null 
          })
          : GridCellPictos({ 
            value: null, 
            id: formatObject.row.id, 
            pageSize: pageSize, 
            index: formatObject.row.index, 
            title: "Définissez un statut de \"Suivi marché + mots-clés\" pour afficher la position SCRAP." 
          });
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'position_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Vol.', 
      dataField: 'volume_value', 
      align: 'center',
      width: 80,
      showDescriptionButton: true, 
      description: 'Nombre de recherches sur Google sur les mots-clés suivis',
      template: formatObject => 
      {
        // if value === -1 -> subitem -> not display the volume
        if( formatObject.value !== -1 )
        {
          formatObject.template = !isNullOrUndefined( formatObject.value ) ?
            GridCellCompareValues({ 
              value: formatObject.value, 
              variation: null, 
              notation: 'compact' 
            })
            : GridCellPictos({ 
              value: null, 
              id: formatObject.row.id, 
              pageSize: pageSize, 
              index: formatObject.row.index,
              title: "Définissez un statut de \"Suivi marché + mots-clés\" pour afficher le volume." 
            });
        } else
          formatObject.template = ' ';
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'volume_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Imp.', 
      dataField: 'impressions_value', 
      align: 'center',
      width: 80,
      showDescriptionButton: true, 
      description: 'Affichage des URLs d’un site dans les SERP de Google',
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 )
    },{ 
      label: '', 
      dataField: 'impressions_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'CTR', 
      dataField: 'ctr_value', 
      align: 'center',
      width: 70,
      showDescriptionButton: true, 
      description: 'Taux de clics sur les URLs de votre site',
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, style: 'percent' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 )
    },{ 
      label: '', 
      dataField: 'ctr_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Tx Occ.', 
      dataField: 'rate_value', 
      align: 'center',
      width: 70,
      showDescriptionButton: true, 
      description: 'Pourcentage d’aire total occupée par le site dans le top 10 de la SERP',
      template: formatObject => {
        formatObject.template = formatObject.value !== -1 ?
          GridCellCompareValues({ 
            value: formatObject.value, 
            variation: null, 
            style: 'percent' 
          })
          : GridCellPictos({ 
              value: null, 
              id: formatObject.row.id, 
              pageSize: pageSize, 
              index: formatObject.row.index,
              title: "Définissez un statut de \"Suivi marché + mots-clés\" pour afficher le pourcentage d'aire total occupée." 
          });
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'rate_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Snippets', 
      dataField: 'snippets', 
      align: 'left',
      width: 126,
      allowSort: false,
      cellsWrap: true,
      showDescriptionButton: true, 
      description: 'Type de résultats présents dans la SERP. Icone entouré en gris = snippet occupé par le site',
      template: formatObject => 
      {
        if( JSON.parse( formatObject.value ).value !== 'subitem' )
        {
          formatObject.template = GridCellPictos(           
            JSON.parse( formatObject.value ).value !== 'unscrap' ? 
              { 
                ...JSON.parse( formatObject.value ), 
                id: formatObject.row.id, 
                pageSize: pageSize, 
                index: formatObject.row.visibleIndex
              } 
              : 
              { 
                value: null, 
                id: formatObject.row.id, 
                pageSize: pageSize, 
                index: formatObject.row.index,
                title: "Définissez un statut de \"Suivi marché + mots-clés\" pour afficher les snippets." 
              } 
            );
        } else
          formatObject.template = ' ';        
      }
    },{ 
      label: 'Statut / Action', 
      dataField: 'status_action', 
      align: 'center',
      width: 100,
      allowSort: false,
      cellsWrap: true,
      showDescriptionButton: true, 
      description: 'Statut : Statut du mot-clé et type de suivi. Actions : Actions SEO à mettre en oeuvre sur le mot-clé',
      template: formatObject => {
        formatObject.template = GridCellStatusAction(
          {
            id: formatObject.row.id, 
            index: formatObject.row.visibleIndex,
            pageSize: pageSize,
            statusId: isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).value
              : undefined,
            statusDatas: userDatas.statusKeyword,
            label: isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).label
              : undefined,
            loadingParams: {
              clientID: loadingParams.clientID,
              device: loadingParams.device,
              location: loadingParams.location,
              currentPeriod: loadingParams.currentPeriod
            },
            reloadTriggerCallBackFct: reloadTriggerCallBackFct
          } 
        );
      }
    }
  ];

  /** Format data source for Grid component */
  const formatDataSourceFct = response => response.map( elem => ({ 
    ...elem, 

    // subtimes toggler
    subitems_toggler: ( !isNullOrUndefined( elem?.gsc?.urls ) && elem?.gsc?.urls.length > 1 ),

    // label
    label: JSON.stringify( { 
      value: elem.label, 
      link: elem.serpHtmlUrl, 
      keywordsGroups: elem.keywordsGroups, 
      currentFilters: searchParams.get('filters')
    }), 

    // volume
    volume: { 
        value: !isNullOrUndefined( elem?.volume?.currentVolume ) ? elem.volume.currentVolume : undefined, 
        variation: !isNullOrUndefined( elem?.volume?.variation ) ? elem.volume.variation : undefined
      },

    // gsc
    gsc: { 
      clicks: { 
          value: !isNullOrUndefined( elem?.gsc?.clicks?.currentValue ) ? elem.gsc.clicks.currentValue : undefined, 
          variation: !isNullOrUndefined( elem?.gsc?.clicks?.variation ) ? elem.gsc.clicks.variation : undefined, 
        },
      impressions: { 
          value: !isNullOrUndefined( elem?.gsc?.impressions?.currentValue ) ? elem.gsc.impressions.currentValue : undefined, 
          variation: !isNullOrUndefined( elem?.gsc?.impressions?.variation ) ? elem.gsc.impressions.variation : undefined, 
        },
      ctr: { 
          value: (
            !isNullOrUndefined( elem?.gsc?.clicks?.currentValue )
            && !isNullOrUndefined( elem?.gsc?.impressions?.currentValue ) 
            && elem.gsc.impressions.currentValue > 0
          ) ? 
            elem.gsc.clicks.currentValue / elem.gsc.impressions.currentValue
            : undefined,
          variation: (
            !isNullOrUndefined( elem?.gsc?.clicks?.currentValue )
            && !isNullOrUndefined( elem?.gsc?.impressions?.currentValue ) 
            && !isNullOrUndefined( elem?.gsc?.clicks?.compareValue ) 
            && !isNullOrUndefined( elem?.gsc?.impressions?.compareValue )
            && elem.gsc.clicks.compareValue > 0 
            && elem.gsc.impressions.compareValue > 0
          ) ?
            ( ( elem.gsc.clicks.compareValue / elem.gsc.impressions.compareValue ) - ( elem.gsc.clicks.currentValue / elem.gsc.impressions.currentValue ) ) * 100
            : undefined
        },
      urls: !isNullOrUndefined( elem?.gsc?.urls ) ? 
        JSON.stringify({ 
          value: elem.gsc.urls.map( url => ({
            url: url.url,
            nbUrls: elem.gsc.urls.length,
            expectedUrl: !isNullOrUndefined( elem?.expectedUrl ) ? 
              getUpdateOrAutoValue( elem.expectedUrl, 'expectedUrl' )
              : null,
            isExpectedUrl: !isNullOrUndefined( elem?.expectedUrl ) ? 
              url.url === getUpdateOrAutoValue( elem.expectedUrl, 'expectedUrl' ) ? true : false
              : null
          }))
        }) 
        : JSON.stringify( { value: null } )
    }, 

    // serp
    serp: elem?.serp ? 
      // scrap case
      { 
        position : elem?.serp?.position ? 
          { 
            value: 
              elem.serp.position.currentValue === null ? 
                elem.serp.position.compareValue !== null ? 
                  101
                  : undefined 
                : elem.serp.position.currentValue,
            movement:
              ( elem.serp.position.currentValue !== null && elem.serp.position.compareValue === 101 ) ? 
                'in' 
              : ( elem.serp.position.currentValue === null && elem.serp.position.compareValue !== null ) ?
                'out'
              : null, 
            variation: 
              elem.serp.position.variation !== null ? 
                Math.floor( elem.serp.position.variation )
                : undefined         
          }
          : { value: undefined, variation: undefined },

        rate: elem?.serp?.rate ?
          {
            value: elem.serp.rate.currentValue === null ? undefined : elem.serp.rate.currentValue, 
            variation: elem.serp.rate.variation === null ? undefined : elem.serp.rate.variation * 100
          }
          : { value: undefined, variation: undefined },

        snippets:
          elem?.serp?.snippetsMe && elem?.serp?.snippetsOthers ?
            JSON.stringify( { value: { valueMe: elem.serp.snippetsMe.currentValue, valueOthers: elem.serp.snippetsOthers.currentValue } } )
          : elem?.serp?.snippetsMe ?
            JSON.stringify( { value: { valueMe: elem.serp.snippetsMe.currentValue, valueOthers: null } } )
          : elem?.serp?.snippetsOthers ?
            JSON.stringify( { value: { valueMe: null, valueOthers: elem.serp.snippetsOthers.currentValue } } )
          : JSON.stringify( { value: { valueMe: null, valueOthers: null } } )
      }
      : // no scrap case
      {
        position: { value: -1 },
        rate: { value: -1 },
        snippets: JSON.stringify( { value: 'unscrap' } )
      }, 

    // categories
    categories: elem?.categories?.category1 && elem?.categories?.category2 && elem?.categories?.category3 ?
      { value: elem.categories.category1 + '|' + elem.categories.category2 + '|' + elem.categories.category3 }
      : null,

    // status
    status_action: !isNullOrUndefined( elem?.status ) ? 
      JSON.stringify({ value: elem.status.statusId, label: elem.label  })
      : JSON.stringify( { value: null, label: elem.label } ),

    // expected url
    expectedUrl: !isNullOrUndefined( elem?.expectedUrl ) ? 
      { value: getUpdateOrAutoValue( elem.expectedUrl, 'expectedUrl' ) }
      : null,

    // subitems
    subitems: !isNullOrUndefined( elem?.gsc?.urls ) ? 
      elem.gsc.urls.map( url => (
      {
        // label
        label: JSON.stringify({ value: '' }),
        
        // volume -> value is setted to -1 to not display it in subitems
        volume: { value: -1 },

        // gsc
        gsc: 
        {
          clicks: { 
            value: url?.clicks?.currentValue, 
            variation: url?.clicks?.variation,
          },
          impressions: {
            value: url?.impressions?.currentValue,
            variation: url?.impressions?.variation,
          },
          ctr: { 
            value: (
              !isNullOrUndefined( url.clicks?.currentValue )
              && !isNullOrUndefined( url.impressions?.currentValue ) 
              && url.impressions.currentValue > 0
            ) ? 
              url.clicks.currentValue / url.impressions.currentValue
              : undefined,
            variation: (
              !isNullOrUndefined( url?.clicks?.currentValue )
              && !isNullOrUndefined( url?.impressions?.currentValue ) 
              && !isNullOrUndefined( url?.clicks?.compareValue ) 
              && !isNullOrUndefined( url?.impressions?.compareValue )
              && url.clicks.compareValue > 0 
              && url.impressions.compareValue > 0
            ) ?
              ( ( url.clicks.compareValue / url.impressions.compareValue ) - ( url.clicks.currentValue / url.impressions.currentValue ) ) * 100
              : undefined
          },
          urls: JSON.stringify({
            value: [{ 
              url: url.url,
              expectedUrl: !isNullOrUndefined( elem?.expectedUrl ) ? 
                getUpdateOrAutoValue( elem.expectedUrl, 'expectedUrl' )
                : null,
              isExpectedUrl: !isNullOrUndefined( elem?.expectedUrl ) ? 
                url.url === getUpdateOrAutoValue( elem.expectedUrl, 'expectedUrl' ) ? true : false
                : null
            }]
          })
        },

        // serp
        serp: elem?.serp ?
          // scrap case
          {
            position: !isNullOrUndefined( elem?.serp?.urls ) ? 
              {
                value: elem.serp.urls.filter( serpUrl => serpUrl.url === url.url ).length === 1 ? 
                  elem.serp.urls.filter( serpUrl => serpUrl.url === url.url )[0].position.currentValue === null ? 
                    elem.serp.urls.filter( serpUrl => serpUrl.url === url.url )[0].position.compareValue !== null ? 
                      101
                      : undefined 
                    : elem.serp.urls.filter( serpUrl => serpUrl.url === url.url )[0].position.currentValue
                  : undefined,
                  
                variation: elem.serp.urls.filter( serpUrl => serpUrl.url === url.url ).length === 1 ?
                  elem.serp.urls.filter( serpUrl => serpUrl.url === url.url )[0].position.variation !== null ? 
                    Math.floor( elem.serp.urls.filter( serpUrl => serpUrl.url === url.url )[0].position.variation )
                    : undefined  
                  : undefined  
              }
              : { value: undefined, variation: undefined },

            snippets: JSON.stringify( { value: 'subitem' } )
          }
          : // no scrap case
          {
            position: { value : 'subitem' }, // the value is setted to subitem to not display if no scrap in subitem
            snippets: JSON.stringify( { value: 'subitem' } )
          }
      }))
      : []
  }));
  
  /** Update call back params */
  useEffect( () => 
  {
    if( dataSource !== null )
    {
      // call callback function with datas for parents elements
      setCallBackParams({
        ...callBackParams,
        gridComponentLoading: dataSource.gridComponentLoading,
        keywords: dataSource.nonFilteredDataSource.length === dataSource.filteredDataSource.length ? 
          'allKeywords'
          : dataSource.filteredDataSource.map( elem => JSON.parse( elem.label ).value ),
        selectedKeywords: dataSource.selectedKeywords,
        labels: dataSource.nonFilteredDataSource.map( elem => JSON.parse( elem.label ).value ),
        keywordsGroups: [ ...new Set( 
          dataSource.nonFilteredDataSource.filter( elem => 
            elem.keywordsGroups 
          ).map( elem => 
            elem.keywordsGroups 
          ).flat() 
        )],
        rankedUrls: [ ...new Set( 
          dataSource.nonFilteredDataSource.map( elem => 
            !isNullOrUndefined( elem?.gsc?.urls ) 
            && Array.isArray( JSON.parse( elem.gsc.urls ).value ) ? 
              JSON.parse( elem.gsc.urls ).value.map( elem => elem.url )
              : null 
          ).filter( elem => 
            elem !== null 
          ).flat()
        )],
        expectedUrls: [ ...new Set( 
          dataSource.nonFilteredDataSource.map( elem => 
            !isNullOrUndefined( elem?.expectedUrl?.value ) ? 
              elem.expectedUrl.value
              : null 
          ).filter( elem => 
            elem !== null 
          )
        )],
        minMaxValues: getMinMaxFromArrayObject( 
          dataSource.nonFilteredDataSource, 
          [ 
            'volume.value',
            'gsc.clicks.value',
            'gsc.impressions.value',
            'gsc.ctr.value',
            'serp.position.value',
            'serp.rate.value'
          ]
        )
      });
    }

  }, [ dataSource ]);

  /** Send callback params with callback function */
  useEffect( () => 
  {
    if( typeof callBackFunction === 'function' )
      callBackFunction( callBackParams );

  }, [ callBackParams ]);

  return(
    <GridCore
      coreType='keywords'
      gridColumns={ gridColumns }
      dataFields={ dataFields }
      loadingParams={{
        clientID: loadingParams.clientID,
        where: {
          locations: [ loadingParams.location ],
          devices: [ loadingParams.device ],
          who: [ 'me' ]
        },
        period: {
          currentPeriod: getDateFromPeriod( loadingParams.currentPeriod, null, 'YYYYMMDD' ),
          comparePeriod: loadingParams.comparePeriod !== 'none' ? 
            getDateFromPeriod( loadingParams.currentPeriod, loadingParams.comparePeriod, 'YYYYMMDD' ) 
            : null
        },
        userId: loadingParams.userId,
        filters: loadingParams.filters,
        dataFieldsToLoad: dataFieldsToLoad
      }}
      displayMode={ displayMode }
      gridSelectedKeywordsGroups={ gridSelectedKeywordsGroups }
      formatDataSourceFct={ formatDataSourceFct }
      callBackFct={ dataSource => setDataSource( dataSource ) }
      pageSize={ pageSize }
      columnHeight={ 72 }
      // rowHeight={ 56 }
      pagerHeight={ 50 }
      pageSizeSelector={ true }
      checkboxesSelection={ true }
      reloadTrigger={ reloadTrigger }
    />
  );
}

export default GridCoreKeywords;