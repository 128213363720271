/** Dependencies */
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

/** Components */
import GridCore from './../GridCore.js';
import GridCellPicTextPicLink from './../../Cells/GridCellPicTextPicLink';
import GridCellCompareValues from './../../Cells/GridCellCompareValues';
import GridCellDropDownMenu from './../../Cells/GridCellDropDownMenu';

/** Helpers */
import { buildDropDownFilterPanel } from './../../../../helpers/filters';
import { 
  isNullOrUndefined,
  isValidJSON,
  getUrlPart
} from './../../../../helpers/functions.js';

/** Class */
import wsEditoCalendar from './../../../../helpers/webservice/wsEditoCalendar.class';

/** JSON */
import i18n from './../../../../assets/json/i18n.json';

/** SCSS */
import './GridCoreEditorCalendar.scss';

function GridCoreEditorCalendar( props )
{
  /** Get props */
  const {
    loadingParams,
    users
  } = props;

  /** useDispatch hook **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Define page size */
  const pageSize = 15;

  /** Define data fields */
  const dataFields = [
    { name: 'eventId', dataType: 'int' },
    { name: 'keyword', dataType: 'string' },
    { name: 'pageType', dataType: 'int' },
    { name: 'volume', dataType: 'int' },
    { name: 'url', dataType: 'string' },
    { name: 'position', dataType: 'float' },
    { name: 'clicks', dataType: 'int' },  
    { name: 'seasonality', dataType: 'string' },
    { name: 'status', dataType: 'int' },
    { name: 'contributor', dataType: 'int' },
    { name: 'publicationDate', dataType: 'date' }
  ];

  /** Define grid columns properties */
  const gridColumns = [
    {
      label: '',
      dataField: 'eventId',
      visible: false
    },{ 
      label: 'Sujet', 
      dataField: 'keyword',
      align: 'left',
      allowEdit: false,
      cellsWrap: true,
      template: formatObject => 
      {
        if( 
          formatObject.value.trim() !== '' 
          && isValidJSON( formatObject.value ) 
        ){
          formatObject.template = GridCellPicTextPicLink({ 
            text: JSON.parse( formatObject.value ).value,
            postPic: 'GoogleLink',
            postPicOptions: { width: 24, height: 24 },
            postPicLink: JSON.parse( formatObject.value ).link
          });
        }
      }
    },{ 
      label: 'Type', 
      dataField: 'pageType',
      align: 'left',
      width: 170,
      allowEdit: false,
      cellsWrap: true,
      template: formatObject => 
      {
        formatObject.template = GridCellDropDownMenu({
          placeHolder: 'Type de page',
          dataSource: userDatas.pageTypes.map( pageType => ({ 
              id: pageType.id, 
              label: pageType.label 
            })),
          currentValue: formatObject.value !== -1 ? 
            { id: parseInt( formatObject.value ) }
            : null,
          callBackFct: result => updateEditorCalendarEvent([
            formatObject.row.data.eventId,
            result,
            null,
            null,
            null,
            null,
            'reloadGrid'  
          ])
        });
      },
      updateFilterPanel: ( filterPanel, column ) => 
      {
        if( isNullOrUndefined( column?.filter?.filters ) )
        {
          // update filter panel
          filterPanel.dataSource = userDatas.pageTypes.map( pageType => ({
            label: pageType.label,
            value: pageType.id
          }));

          // reset onFilter function
          filterPanel.onFilter = () => onFilterEQUAL( 
            column, 
            filterPanel, 
            userDatas.pageTypes.map( pageType => ({
              label: pageType.label,
              value: pageType.id
            }))
          );
        }
      },
      getFilterPanel: column => 
      {
        // build panel and return value
        return buildDropDownFilterPanel( 
          userDatas.pageTypes.map( pageType => ({
            label: pageType.label,
            value: pageType.id
          })), 
          column,
          onFilterEQUAL
        );
      }
    },{ 
      label: 'Volume', 
      dataField: 'volume',
      align: 'center',
      cellsAlign: 'center',
      width: 100,
      allowEdit: false,
      cellsWrap: true,
      template: formatObject => {
        formatObject.template = GridCellCompareValues({ 
          value: formatObject.value, 
          variation: null, 
          notation: 'compact' 
        });
      }
    },{ 
      label: 'URL', 
      dataField: 'url',
      align: 'left',      
      cellsWrap: true,
      template: formatObject => 
      {
        // set template
        formatObject.template = GridCellPicTextPicLink( !isNullOrUndefined( formatObject?.value ) ? 
          { 
            text: getUrlPart( formatObject.value, 'pathname' ),
            textLink: formatObject.value
          }
          : null 
        );
      }
    },{ 
      label: 'Pos.', 
      dataField: 'position',
      align: 'center',
      width: 80,
      cellsWrap: true,
      template: formatObject => {
        formatObject.template = GridCellCompareValues({
          value: formatObject.value, 
          variation: null, 
          notation: 'compact' 
        });
      }
    },{ 
      label: 'Clics', 
      dataField: 'clicks',
      align: 'center',
      width: 100,
      cellsWrap: true,
      template: formatObject => {
        formatObject.template = GridCellCompareValues({ 
          value: formatObject.value, 
          variation: null, 
          notation: 'compact' 
        });
      }
    },{ 
      label: 'Saisonnalité', 
      dataField: 'seasonality',
      align: 'left',
      width: 150,
      allowSort: false,
      allowEdit: false,
      allowFilter: false,
      cellsWrap: true,
      showActionButton: false,
      template: 'sparklines',
      templateSettings: {
				type: 'column',
        gap: 4,
        colorFunction: () => 'var( --color-grey2 )',
        tooltipFormatFunction: value =>  Intl.NumberFormat('fr-FR', { notation: 'compact' }).format( value )
      }
    },{ 
      label: 'État', 
      dataField: 'status',
      align: 'left',
      width: 170,
      allowEdit: false,
      cellsWrap: true,
      template: formatObject => 
      {
        formatObject.template = GridCellDropDownMenu({
          placeHolder: 'État',
          dataSource: userDatas.statusEdito.map( status => ({ 
              id: status.id, 
              label: status.label 
            })),
          currentValue: formatObject.value !== -1 ? 
            { id: parseInt( formatObject.value ) }
            : null,
          callBackFct: result => updateEditorCalendarEvent([
            formatObject.row.data.eventId,
            null,
            null,
            result,
            null,
            null,
            'reloadGrid'  
          ])
        });
      },
      updateFilterPanel: ( filterPanel, column ) => 
      {
        if( isNullOrUndefined( column?.filter?.filters ) )
        {
          // update filter panel
          filterPanel.dataSource = userDatas.statusEdito.map( status => ({
            label: status.label,
            value: status.id
          }));

          // reset onFilter function
          filterPanel.onFilter = () => onFilterEQUAL( 
            column, 
            filterPanel, 
            userDatas.statusEdito.map( status => ({
              label: status.label,
              value: status.id
            }))
          );
        }
      },
      getFilterPanel: column => 
      {
        // build panel and return value
        return buildDropDownFilterPanel( 
          userDatas.statusEdito.map( status => ({
            label: status.label,
            value: status.id
          })), 
          column,
          onFilterEQUAL
        );
      }
    },{ 
      label: 'Rédacteur', 
      dataField: 'contributor',
      align: 'left',
      width: 200,
      allowEdit: false,
      cellsWrap: true,      
      template: formatObject => 
      {
        formatObject.template = GridCellDropDownMenu({
          placeHolder: 'Rédacteur',
          dataSource: !isNullOrUndefined( users ) ? 
            users.map( user => ({ 
              id: user.id, 
              label: user.firstName + ' ' + user.lastName 
            })) 
            : null,
          currentValue: formatObject.value !== -1 ? 
            { id: parseInt( formatObject.value ) }
            : null,
          callBackFct: result => updateEditorCalendarEvent([
            formatObject.row.data.eventId,
            null,
            null,
            null,
            result,
            null,
            'reloadGrid'  
          ])
        });
      },
      updateFilterPanel: ( filterPanel, column ) => 
      {
        if( isNullOrUndefined( column?.filter?.filters ) )
        {
          // update filter panel
          filterPanel.dataSource = users.map( user => ({ 
            label: user.firstName + ' ' + user.lastName, 
            value: user.id 
          }));

          // reset onFilter function
          filterPanel.onFilter = () => onFilterEQUAL( 
            column, 
            filterPanel, 
            users.map( user => ({ 
              label: user.firstName + ' ' + user.lastName, 
              value: user.id 
            }))
          );
        }
      },
      getFilterPanel: column => 
      {
        // build panel and return value
        return buildDropDownFilterPanel( 
          users.map( user => ({ 
            label: user.firstName + ' ' + user.lastName, 
            value: user.id 
          })), 
          column,
          onFilterEQUAL
        );
      }
    },{ 
      label: 'Publication', 
      dataField: 'publicationDate',
      align: 'left',
      width: 140,
      cellsFormat: 'dd/MM/yyyy',
      cellsWrap: true,
      editor: {
        template: 'dateTimePicker', 
        settings: { 
          locale: 'fr',
          messages: i18n,
          firstDayOfWeek: 1,
          editMode: 'partial',
          formatString: "dd/MM/yyyy"
        }
      },
      template: formatObject => 
      {
        formatObject.template = GridCellPicTextPicLink({ 
          text: !isNullOrUndefined( formatObject?.value ) ? 
            moment( formatObject.value ).format( 'DD/MM/YYYY' ) 
            : <span>Aucune</span>
        });
      }
    }
  ];

  /** Format data source for Grid component */
  const formatDataSourceFct = response => response.map( elem => ({ 

    // user id
    eventId: elem.id,

    // keyword
    keyword: JSON.stringify({
      value: elem.keyword,
      link: elem.serpHtmlUrl
    }),

    // page type
    pageType: isNullOrUndefined( elem.pageTypeId ) ? -1 : elem.pageTypeId,

    // volume
    volume: elem.volume,

    // url
    url: elem.url,

    // position
    position: elem.position,

    // clicks
    clicks: elem.clicks,

    // seasonality
    seasonality: !isNullOrUndefined( elem.seasonality ) ? elem.seasonality.map( elem => elem.volume ).toString() : '',

    // status
    status: isNullOrUndefined( elem.statusId ) ? -1 : elem.statusId,

    // contributor
    contributor: isNullOrUndefined( elem.contributorId ) ? -1 : elem.contributorId,
    
    // publication date
    publicationDate: elem.publicationDate

  }));

  const updateEditorCalendarEvent = params => 
  {
    new wsEditoCalendar(
      'grid-loader-edito-calendar',
      loadingParams.location,
      dispatch,
      loadingParams.clientID
    ).update.apply( this, params );
  }

  const onFilterEQUAL = ( column, panel, itemsList ) => 
  {
    // get selected indexes
    const selectedIndexes = panel.querySelector( 'smart-tree' ).selectedIndexes
      .map( elem => parseInt( elem.split( '.' )[1] ) )
      .filter( elem => !isNullOrUndefined( elem ));

    // get selected values          
    const selectedValues = itemsList.filter( ( elem, index ) => 
      selectedIndexes.includes( index ) 
    ).map( elem => 'EQUAL ' + elem.value );

    // set column filter
    column.filter = selectedValues.join( ' or ' );
  }
  
  return(
    <GridCore
      coreType='edito-calendar'
      gridColumns={ gridColumns }
      dataFields={ dataFields }
      loadingParams={{
        clientID: loadingParams.clientID,
        period: {
          currentPeriod: loadingParams.currentPeriod,
          comparePeriod: 'none'
        },
        where: {
          locations: [ loadingParams.location ]
        }
      }}
      displayMode='full-grid'
      formatDataSourceFct={ formatDataSourceFct }
      pageSize={ pageSize }
      filtering={ true }
      editing={ true }
      rowHeight={ 56 }
      columnHeight={ 72 }
      pagerHeight={ 50 }
      onEndEditFct={ e => 
      {
        if( 
          !isNullOrUndefined(  e.detail.cell.value )
          && e.detail.cell.value !== e.detail.cell.oldValue 
        ){
          // choose action to call with datafield updated name
          switch ( e.detail.dataField ) 
          {            
            case 'url':
              updateEditorCalendarEvent([
                  e.detail.data.eventId, 
                  null,
                  e.detail.cell.value,
                  null,
                  null,
                  null,
                  'reloadGrid'      
              ]);
              break;

            case 'publicationDate':
              updateEditorCalendarEvent([
                e.detail.data.eventId, 
                null,
                null,
                null,
                null,
                moment( e.detail.cell.value ).format( 'YYYY-MM-DD' ),
                'reloadGrid'      
              ]);
              break;

            default: void(0); 
              break;
          }
          
          // Update cell value with new label
          e.detail.cell.oldValue = e.detail.cell.value;
        }
      }}
    />
  );
}

export default GridCoreEditorCalendar;