/** Dependencies */
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'smart-webcomponents-react/input';
import { Button } from 'smart-webcomponents-react/button';
import { RadioButton } from 'smart-webcomponents-react/radiobutton';
import moment from 'moment';

/** Component */
import Loader from './../Loader/Loader';

/** Helpers */
import { isNullOrUndefined, sortArrayByObjectField } from './../../helpers/functions.js';
import { getUpdateOrAutoValue } from './../../helpers/datas.js';
import { getPicto } from './../../helpers/pictos';
import { callWebservice } from './../../helpers/webservice/webserviceCaller';

/** Class */
import wsExpectedUrl from './../../helpers/webservice/wsExpectedUrl.class';

/** Services */
import { getItem } from './../../services/LocaleStorage.js';

/** SCSS */
import './ExpectedURL.scss';

function ExpectedURL( props )
{
  /** Get props */
  const {
    keywords,
    loadingParams,
    cancelCallBackFct,
    reloadTriggerCallBackFct
  } = props;

  /** Instance Dispatch Hook **/
	const dispatch = useDispatch();

  /** Get user id */
  const userId = getItem( 'userId' );

  /** Define refs */
  const expectedUrlContainer = useRef( null );
  const radioButtonOtherUrlRef = useRef( null );
  const inputOtherUrlRef = useRef( null );

  /** Define colors */
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Init State */
  const [ bestClickUrl, setBestClickUrl ] = useState( null );
  const [ othersUrls, setOthersUrls ] = useState( null );
  const [ currentValue, setCurrentValue ] = useState( null );

  const loadBestClickUrl = () => 
  {
    callWebservice(
      'expected-url-loader',
      'expected-url-bestClick-loader',
      'gsc-max-clicks-by-keywords',
      dispatch,
      loadingParams.clientID,
      {
        period: {
          startDate: moment().subtract( 1, 'year' ).format('YYYYMMDD'),
          endDate: moment().format('YYYYMMDD')
        },
        where: {
          keywords: Array.isArray( keywords ) ? keywords : [ keywords ],
          locations: [ loadingParams.location.split( '|' )[0] ],
          devices: [ loadingParams.device ]
        }
      },
      { function: 'setBestClickUrl' }
    );
  }

  const loadOthersUrls = () =>
  {
    callWebservice(
      'expected-url-loader',
      'expected-url-OthersUrls-loader',
      'gsc',
      dispatch,
      loadingParams.clientID,
      {
        cols: ["DISTINCT url"],
        where: {
          locations: [ loadingParams.location ],
          devices: [ loadingParams.device ]
        }
      },
      { function: 'setOthersUrls' }
    );
  }

  const loadCurrentValue = () => 
  {
    if( 
      typeof keywords === 'string' 
      || Array.isArray( keywords )
    ){
      // update value in DB
      new wsExpectedUrl(
        'expected-url-loader',
        loadingParams.device,
        loadingParams.location,
        dispatch,
        loadingParams.clientID
      ).getExpectedUrl( 
        keywords,
        'setCurrentValue'          
      );
    }
  }

  const validForm = () => 
  {
    if(
      !isNullOrUndefined( currentValue?.url )
      && currentValue.url.trim() !== ''
      && !isNullOrUndefined( currentValue?.type )
      && currentValue.type.trim() !== ''
    ){
      // update value in DB
      new wsExpectedUrl(
        'expected-url-loader',
        loadingParams.device,
        loadingParams.location,
        dispatch,
        loadingParams.clientID
      ).addExpectedUrl( 
        keywords,
        currentValue.url,
        currentValue.type,
        userId,
        'validForm'     
      );
    }
  }

  /** Load datas */
  useEffect( () => 
  {
    // load best click url
    loadBestClickUrl();

    // load others urls
    loadOthersUrls();

    // load current expected url
    loadCurrentValue();

  }, []);

  return(
    <div className="expected-url-container" ref={ expectedUrlContainer }>

      {/* Loader */}
      <Loader 
        loaderID='expected-url-loader'
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setCurrentValue: results => setCurrentValue( 
            typeof keywords === 'string'
            && !isNullOrUndefined( results[keywords] ) ?
              {
                url: getUpdateOrAutoValue( results[keywords][0], 'expectedUrl' ),
                type: results[keywords][0]['type']
              }
              : null 
          ),
          setOthersUrls: results => setOthersUrls( results.map( elem => elem.url ) ),
          setBestClickUrl: results => setBestClickUrl( 
            (
              typeof keywords === 'string'
              && !isNullOrUndefined( results[keywords] ) 
              && results[keywords].filter( elem => elem.maxClicks > 0 ).length > 0
            ) ? 
              results[keywords][0].url
            : 
            (
              Array.isArray( keywords )
              && keywords.length > 0 
              && keywords.map( keyword => 
                !isNullOrUndefined( results[keyword] ) && results[keyword].filter( elem => elem.maxClicks > 0 ).length > 0 ? results[keyword] : [] 
              ).flat().length > 0 
            ) ? 
              sortArrayByObjectField( 
                keywords.map( keyword => 
                  !isNullOrUndefined( results[keyword] ) 
                  && results[keyword].filter( elem => elem.maxClicks > 0 ).length > 0 ? 
                    results[keyword] 
                    : [] 
                ).flat(), 
                'maxClicks',
                'number' 
              )[0].url
            : null
          ),
          validForm: results => 
          { 
            // set current value
            setCurrentValue( results ); 

            // close popin
            cancelCallBackFct.call();
            
            // call reload grid call back function
            if( typeof reloadTriggerCallBackFct === 'function' ) reloadTriggerCallBackFct(); 
          }
        }}
      />
      
      {
        !isNullOrUndefined( bestClickUrl )
        && bestClickUrl.trim() !== '' ?
          <div className="best-url">
            <RadioButton          
              groupName="group1"
              innerHTML="URL préconisée (plus grand nombre de clics)"
              checked={( 
                !isNullOrUndefined( currentValue?.type ) 
                && currentValue.type === 'bestclickedurl' 
                && !isNullOrUndefined( currentValue?.url ) 
                && !isNullOrUndefined( bestClickUrl )
                && currentValue.url === bestClickUrl
              )}
              onChange={ () => setCurrentValue({ 
                ...currentValue, 
                ...{ 
                  url: bestClickUrl !== null ? bestClickUrl : '',
                  type: 'bestclickedurl' 
                } 
              })}
            />

            <div className='input-url-container'>
              <Input 
                id='best-url-input'
                disabled
                value={ bestClickUrl !== null ? bestClickUrl : '' }
                onClick={ e => 
                {
                  // Add set time out to focus this input after click event on grid which unfocus it
                  window.setTimeout( () => e.target.focus(), 500 ); 
                }}
              ></Input>
              <button 
                onClick={ () => !isNullOrUndefined( bestClickUrl ) && bestClickUrl.trim() !== '' ? 
                  window.open( bestClickUrl, '_blank' ) 
                  : null 
                }
              >{ getPicto( 'ArrowSquareOut', { size: '1.5rem', fill: grey2Color } ) }</button>
            </div>
          </div>
        : null
      }

      <div className="other-url">
        <RadioButton 
          ref={ radioButtonOtherUrlRef }
          groupName="group1"
          innerHTML="Autre URL existante"
          checked={ !isNullOrUndefined( currentValue?.type ) && currentValue.type === 'otherurl' }
          onChange={ () => !isNullOrUndefined( currentValue?.url ) ?
            setCurrentValue({ 
              ...currentValue, 
              ...{ 
                url: currentValue.type === 'otherurl' ? 
                  currentValue.url 
                  : '',
                type: 'otherurl' 
              }
            })
            : null
          }
        />

        <div className='input-url-container'>
          <Input 
            id='other-url-input'
            ref={ inputOtherUrlRef }
            dataSource={ othersUrls }
            queryMode={ 'contains' }
            value={ ( !isNullOrUndefined( currentValue?.url ) && currentValue.type === 'otherurl' ) ? 
              currentValue.url 
              : ''
            }
            onChanging={ e => !isNullOrUndefined( e?.detail?.value ) ? 
              setCurrentValue({ 
                ...currentValue, 
                ...{ 
                  url: e.detail.value,
                  type: 'otherurl' 
                }
              }) 
              : null 
            }
            onChange={ e => !isNullOrUndefined( e?.detail?.value ) ? 
              setCurrentValue({ 
                ...currentValue, 
                ...{ 
                  url: e.detail.value,
                  type: 'otherurl' 
                }
              }) 
              : null 
            }
            onItemClick={ e => !isNullOrUndefined( e?.detail?.value ) ? 
              setCurrentValue({ 
                ...currentValue, 
                ...{ 
                  url: e.detail.value,
                  type: 'otherurl' 
                }
              }) 
              : null 
            }
            onClick={ e => 
            {
              // checked radio button
              radioButtonOtherUrlRef.current.checked = true;

              // Add set time out to focus this input after click event on grid which unfocus it
              window.setTimeout( () => e.target.focus(), 500 ) 
            }}
            onOpen={ () => 
            {
              document.querySelector( '.smart-input-drop-down-menu' ).style.top = ( inputOtherUrlRef.current.nativeElement.getBoundingClientRect().y + 27 ) + 'px';
            }}
            dropDownWidth={ 475 }
          ></Input>
          <button 
            onClick={ () => !isNullOrUndefined( currentValue?.type ) && currentValue.type === 'otherurl' ? 
              window.open( currentValue.url, '_blank' ) 
              : null 
            }
          >{ getPicto( 'ArrowSquareOut', { size: '1.5rem', fill: grey2Color } ) }</button>
        </div>
      </div>

      <div className='confirm-container' id='expected-url-confirm-container'>
        <Button 
          className='flat empty'
          onClick={ () => typeof cancelCallBackFct === 'function' ? cancelCallBackFct() : null }
        >Annuler</Button>

        <Button 
          className='flat fill' 
          onClick={ validForm }  
        >Enregistrer</Button>
      </div>

    </div>
  )
}

export default ExpectedURL;