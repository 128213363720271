/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsUsers
{
  constructor(
    loaderID,
    dispatch,
    selectedInstance
  ){
    this.loaderID = loaderID;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;
  }

  get = (
    roleId = null,
    requestPasswordToken = null,
    callBackFct = null
  ) => {

    this.exec(
      'get-users',
      null,
      null, 
      null, 
      null,
      roleId,
      null,
      null,
      requestPasswordToken,
      null,
      callBackFct
    );
  }

  add = ( 
    firstName, 
    lastName, 
    email,
    roleId,
    callBackFct = null
  ) => 
  {
    this.exec(
      'add-user',
      null,
      firstName, 
      lastName, 
      email,
      roleId,
      null,
      window.location.origin + '/admin/resetPassword',
      null,
      null,
      callBackFct
    );
  }
  
  update = (
    userId,
    firstName = null, 
    lastName = null, 
    email = null, 
    roleId = null,
    password = null,
    requestPasswordToken = null,
    lastRequestPasswordDatetime = null,
    callBackFct = null
  ) => {

    this.exec(
      'update-user',
      userId,
      firstName, 
      lastName, 
      email,
      roleId,
      password,
      null,
      requestPasswordToken,
      lastRequestPasswordDatetime,
      callBackFct      
    );
  }

  del = (
    userId,
    callBackFct = null
  ) => {

    this.exec(
      'del-user',
      userId, 
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      callBackFct
    );
  }

  resetPassword = (
    userId,
    firstName = null, 
    lastName = null, 
    email = null,
    callBackFct = null
  ) => {

    this.exec(
      'reset-user-password',
      userId, 
      firstName, 
      lastName, 
      email,
      null,
      null,
      window.location.origin + '/admin/resetPassword',
      null,
      null,
      callBackFct
    );
  }
  
  exec = (
    serviceName,
    userId = null,
    firstName = null, 
    lastName = null, 
    email = null,
    roleId = null,
    password = null,
    redirectUrl = null,
    requestPasswordToken = null,    
    lastRequestPasswordDatetime = null,    
    callBackFct = null
  ) => {

    callWebservice( 
      this.loaderID,
      serviceName,
      serviceName,
      this.dispatch,
      this.selectedInstance,
      {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        roleId: roleId,
        password: password,
        redirectUrl: redirectUrl,
        requestPasswordToken: requestPasswordToken,
        lastRequestPasswordDatetime: lastRequestPasswordDatetime,
        where: {
          roleId: roleId
        }
      },
      callBackFct !== null ? {
        function: callBackFct
      } : null
    );
  }
}