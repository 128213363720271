/** Dependencies */
import { cloneElement, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'smart-webcomponents-react/button';
import { Window } from 'smart-webcomponents-react/window';

/** Reducers */
import { onToggler, offToggler } from './../../reducers/togglers';

/** Helpers */
import { isNullOrUndefined } from './../../helpers/functions';

/** SCSS */
import './WindowButton.scss';

function WindowButton( props )
{
  const {
    id,
    className,    
    activeEffect,
    windowContent,
    buttonContent,
    indicator,
    width,
    height,
    mouseEventType = 'click',
    positionClassName,
    buttonClassName,
    disabled,
    onOpen,
    onClose
  } = props;

  /** Define id */
  const currentId = id + '-windowbutton';

  /** Refs */
  const windowRef = useRef();

  /** Instance dispatch object */
	const dispatch = useDispatch();

	/** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

  /** Define class with effect */
  let classEffect = [ 'up', 'down' ];
  if( activeEffect === 'horizontalFlip' )
    classEffect = [ 'right', 'left' ];

  /** Close / open window */
  const openCloseToggler = () => 
  {
    // close window
    if( windowRef.current.opened )
    {
      windowRef.current.close();
      dispatch( offToggler( currentId ) );

    // open window
    } else {
      windowRef.current.open();
      dispatch( onToggler( currentId ) );
    }
  }

  /** Add event click on document to close the window if not active */
  useEffect( () => 
  {
    // close function on click
    const handleClick = ( e, windowRef ) => 
    { 
      if( 
        e.target.closest( '#' + id + '-window' ) === null
        && e.target.closest( '#' + id + '-button' ) === null
        && e.target.closest( '[id^="filter-"]' ) === null // use for filters removers
        && e.target.closest( '[id^="other-url-input"]' ) === null // use for expected url other url selector
        && e.target.closest( '[id^="expected-url-confirm-container"]' ) === null // use for expected url cancel button
        && e.target.closest( '[id^="areyousure-confirm-container"]' ) === null // use for remove keyword cancel button
        && e.target.closest( '[id^="grid-component-admin-keywords_smart-grid_menu_"]' ) === null // use for sort menu in admin grid component
        && e.target.closest( '[id^="admin-expected-url"]' ) === null // use for expected url other url selector        
        && e.target.closest( '[id^="admin-add-keywords-confirm-container"]' ) === null // use for mass actions add keywords cancel button
        && e.target.closest( '[id^="admin-add-keywords-expected-url"]' ) === null // use for expected url other url selector        
        && e.target.closest( '[id^="admin-add-keywords-expected-url-mass-actions"]' ) === null // use for expected url other url selector        
        && !e.target.classList.contains( 'smart-pager-page-index-selector' ) // use for expected url cancel button
        && windowRef.current !== null
        && windowRef.current.opened === true
      ){
        
        // close window
        windowRef.current.close();

        // set window open state to false
        dispatch( offToggler( id + '-windowbutton' ) );
      } 
    };
  
    // add click listener on document
    document.addEventListener( 'click', e => handleClick( e, windowRef ) );    
    return () => {
      document.removeEventListener( 'click', e => handleClick( e, windowRef ) );
    };
    
  }, []);

  /** Update open/close status with togglers */
  useEffect( () => togglers[ currentId ] && windowRef.current ? 
    windowRef.current.open() 
    : windowRef.current.close()
    
  , [ togglers[ currentId ] ] );

  /** Resize window width/heigt with width/height prop */
  useEffect( () => 
  {
    if( width !== null && windowRef.current !== null )
      windowRef.current.nativeElement.style.width = width + 'px';

    if( height !== null && windowRef.current !== null )
      windowRef.current.nativeElement.style.height = height + 'px';

  }, [ width, height ])

  return(
    <div className={ className ? 'window-button ' + className : 'window-button' }>

      {/* Indicator */}
      { indicator ? 
          <div className='caps-indicator'>{ indicator }</div>
          : null
      }

      {/* Button */}
      <Button 
        id={ id + '-button' }
        className={ 
          ( !isNullOrUndefined( buttonClassName ) ? buttonClassName + ' ' : 'flat circle ' )
          + ( togglers[ currentId ] ? 'active ' + classEffect[0] : classEffect[1] ) 
        }
        onClick={ mouseEventType === 'click' ? openCloseToggler : null }
        onMouseEnter={ mouseEventType === 'over' ? openCloseToggler : null }
        onMouseLeave={ mouseEventType === 'over' ? openCloseToggler : null }
        disabled={ !isNullOrUndefined( disabled ) ? disabled : false }
      >
        { buttonContent }
      </Button>

      {/* Window */}
      <Window 
        id={ id + '-window' }
        ref={ windowRef }
        headerPosition='none'
        className={ positionClassName !== undefined ? positionClassName : '' }
        onOpen={ () => typeof onOpen === 'function' ? onOpen.call() : null }
        onClose={ () => typeof onClose === 'function' ? onClose.call() : null }
      >
        { togglers[ currentId ] ? cloneElement( windowContent, { openCloseToggler : openCloseToggler } ) : null }
      </Window>
      
    </div>
  )
}

export default WindowButton;