/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsEditoCalendar
{
  constructor(
    loaderID,
    locations,
    dispatch,
    selectedInstance
  ){
    this.locations = locations;
    this.loaderID = loaderID;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;
  }

  get = ( 
    callBackFct = null
  ) => 
  {
    this.exec(
      'get-edito-calendar',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      callBackFct
    );
  }

  add = ( 
    keywords,
    url,
    userId,
    callBackFct = null
  ) => 
  {
    this.exec(
      'add-edito-calendar',
      null,
      keywords,
      null,
      url,
      null,
      null,
      null,
      userId,
      callBackFct
    );

  }
  
  update = (
    eventIds, 
    pageTypeId,
    url,
    statusId,
    contributorId,
    publicationDate,
    callBackFct = null
  ) => 
  {
    this.exec(
      'update-edito-calendar',
      eventIds, 
      null,
      pageTypeId,
      url,
      statusId,
      contributorId,
      publicationDate,
      null,
      callBackFct
    );
  }
  
  exec = (
    serviceName,
    eventIds,
    keywords,
    pageTypeId,
    url,
    statusId,
    contributorId,
    publicationDate,
    userId,
    callBackFct = null
  ) => 
  {
    callWebservice( 
      this.loaderID,
      serviceName,
      serviceName,
      this.dispatch,
      this.selectedInstance,
      {
        keyword: Array.isArray( keywords ) && keywords[0] ? keywords[0] : keywords,
        keywords: Array.isArray( keywords ) ? keywords : [ keywords ],
        pageTypeId: pageTypeId,
        url: url,
        statusId: statusId,
        contributorId: contributorId,
        publicationDate: publicationDate,
        location: Array.isArray( this.locations ) && this.locations[0] ? this.locations[0] : this.locations,
        userId: userId,
        where: {
          eventIds: Array.isArray( eventIds ) ? eventIds : [ eventIds ]
        }
      },
      callBackFct !== null ? {
        function: callBackFct
      } : null
    );
  }
}