/** Dependencies */
import { Tooltip } from 'smart-webcomponents-react/tooltip';
import { useSearchParams } from "react-router-dom";

/** SCSS */
import './RangeGranularity.scss';

/** Helpers */
import { isNullOrUndefined } from './../../helpers/functions';
import { getPeriodGranularity } from './../../helpers/dates';
import { Fragment } from 'react';

function RangeGranularity( props )
{
  const {
    hightLightOnly
  } = props;

  /** Get instance infos from url */
  let [ searchParams ] = useSearchParams();

  // set string for tooltip
  const htmlContent = '<ul><li>12/12 : problème de plantage serveur</li><li>24/12 : le père noël est resté coincé dans la cheminée</li><li>31/12 : trop de chartreuse</li></ul>';
  
  // get current selected period
  const currentPeriod = !isNullOrUndefined( searchParams.get( 'currentPeriod' ) ) ? 
    searchParams.get( 'currentPeriod' ) 
    : '12M' ;

  // get current period granularity
  const periodGranularity = !isNullOrUndefined( searchParams.get( 'periodGranularity' ) ) ?
    getPeriodGranularity( currentPeriod, searchParams.get( 'periodGranularity' ) ) 
    : getPeriodGranularity( currentPeriod );
  
  return(
    <div className={ hightLightOnly ? 'range-granularity highlight-only' : 'range-granularity' } style={{ gridTemplateColumns: 'repeat( ' + periodGranularity.length + ', 1fr )' }}>
      
      {/* display perdiod granularity */}
      { periodGranularity.map( ( elem, index ) => 

          <div className="item" key={ index }>
            <div className='highlight'>&nbsp;</div>
            <div className='highlight-selector'>&nbsp;</div>
            
            { !hightLightOnly ?
                <span className={ periodGranularity.length > 24 ? 'rotate' : '' }>
                  { 
                    // week case
                    elem.split( '|' ).length === 2 ?
                      <Fragment>
                        { elem.split( '|' )[0] } { periodGranularity.length < 24 ? <Fragment><br />{ elem.split( '|' )[1] }</Fragment> : void( 0 ) }
                      </Fragment>                  

                    // month case with year
                    : elem.split( ' ' )[1] === 'janv.' ? 
                      <Fragment>
                        <span className='year'>{ elem.split( ' ' )[0] }</span>{ elem.split( ' ' )[1] } 
                      </Fragment>

                    // month case
                    : elem.split( ' ' )[1]
                  }
                </span>
                : null
            }

            {/* <div className='events-wrapper'>
              <div className='events-bubble' id='button-1'>4</div>
              <Tooltip 
                selector='button-1'
                content={ htmlContent }
                position='bottom'
                arrow
              />
            </div> */}
          </div>
      )}      
    </div>
  )
}

export default RangeGranularity;