/** Dependencies */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, generatePath, useSearchParams, useLocation } from "react-router-dom";
import { ButtonGroup } from 'smart-webcomponents-react/buttongroup';

/** Helpers */
import { getInstanceDatas } from './../../helpers/instance';

/** SCSS */
import './InstanceInfos.scss';

function InstanceInfos( props )
{
  const {
    deviceButtonsDisabled,
    locationButtonDisabled
  } = props;

  /** Instance useNavigate Hook */
  const navigate = useNavigate();

  /** Instance useLocation Hook */
  const { pathname } = useLocation();

  /** Instance useSearchParams Hook */
  let [ searchParams ] = useSearchParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID, location, device } = useParams();

  // get instance datas from user datas
  const instanceDatas = getInstanceDatas( clientID, userDatas );

  return(
      <Fragment>

        <div className='identity'>
          { /* Picture */
            instanceDatas.picture ? 
              <img src={ instanceDatas.picture } alt={ instanceDatas.label } title={ instanceDatas.label } className="picture" />
              : null
          }
          
          { /* Instance Label */
            instanceDatas.label ?
              <h2>{ instanceDatas.label }</h2>
              : null
          }
        </div>

        <div className='device-location'>

          { /* Instance device / location */
            instanceDatas.devLoc ?
              <div className='button-group-instance'>
                {
                  !locationButtonDisabled ?
                    <ButtonGroup  
                      key={ location }
                      dataSource={
                        [...new Map( 
                          instanceDatas.devLoc.map( devLoc => [ devLoc['countryValue'], devLoc ] ) ).values()
                        ].map( devLoc => ( { 
                            label: devLoc.countryLabel, 
                            value: devLoc.countryValue 
                        }))
                      }
                      selectedValues={[ location ]}
                      onChange={ e => navigate({
                        pathname: generatePath( 
                          '/' + pathname.split( '/' )[1] + '/:clientID/:location/:device', 
                          { clientID: clientID, location: e.detail.values[0], device: device }
                        ),
                        search: searchParams.toString()
                      })} 
                    />
                    : void( 0 )
                }

                {
                  !deviceButtonsDisabled ?
                    <ButtonGroup
                      key={ device }
                      dataSource={[
                        { 
                          label: '<i class="ph ph-laptop" style="font-size: 1.6rem"></i>', 
                          value: "desktop",
                          disabled: instanceDatas.devLoc.filter( devLoc => 
                            devLoc.countryValue === location 
                            && devLoc.deviceValue === 'desktop' 
                          ).length >= 1 ? false : true
                        },
                        { 
                          label: '<i class="ph ph-device-mobile-speaker" style="font-size: 1.3rem"></i>', 
                          value: "mobile",
                          disabled: instanceDatas.devLoc.filter( devLoc => 
                            devLoc.countryValue === location 
                            && devLoc.deviceValue === 'mobile' 
                          ).length >= 1 ? false : true
                        }
                      ]}                
                      selectedValues={[ device ]}
                      onChange={ e => navigate({
                        pathname: generatePath( 
                          '/' + pathname.split( '/' )[1] + '/:clientID/:location/:device', 
                          { clientID: clientID, location: location, device: e.detail.values[0] }
                        ),
                        search: searchParams.toString()
                      })}
                    />
                    : void( 0 )
                }
              </div>     
              : null       
          }
        </div>
    </Fragment>
  );
}

export default InstanceInfos;