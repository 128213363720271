/** Dependencies */
import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Input from 'smart-webcomponents-react/input';
import DropDownList from 'smart-webcomponents-react/dropdownlist';
import Button from 'smart-webcomponents-react/button';

/** Components */
import Loader from './../../Loader/Loader';

/** Helpers */
import { isNullOrUndefined } from './../../../helpers/functions';

/** Object class */
import wsUsers from './../../../helpers/webservice/wsUsers.class.js';

/** SCSS */
import './AdminMenuContextualAddUsers.scss';

function AdminMenuContextualAddUsers( props )
{
  const {
    params,
    updateReloadTrigger,
    openCloseToggler
  } = props;

  /** useDispatch hook **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID } = useParams();

  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Get role */
  const roleId = userDatas.enabled_instances.find( elem => elem.nickname === clientID ).roleId

  /** Init useState Hook */
  const [ firstName, setFirstName ] = useState( null );
  const [ lastName, setLastName ] = useState( null );
  const [ email, setEmail ] = useState( null );
  const [ role, setRole ] = useState( null );

  /** Get existing emails */
  let existingEmails = [];
  if( Array.isArray( params ) && params.length > 0 )
    existingEmails = params.map( elem => elem.email );

  /** Add user function */
  const addUser = () => 
  {
    if(
      !isNullOrUndefined( firstName )
      && !isNullOrUndefined( lastName )
      && !isNullOrUndefined( email )
      && !existingEmails.includes( email )
      && !isNullOrUndefined( role )
    ){
      new wsUsers(
        'admin-add-users',
        dispatch,
        clientID
      ).add( 
        firstName,
        lastName,
        email,
        userDatas.roles.find( elem => elem.label === role ).id
      );
    }
  }

  /** Check if email already exist in data grid */
  useEffect( () => 
  {
    if( existingEmails.includes( email ) )
      document.getElementById( 'admin-add-users-email' ).classList.add( 'invalid' );
    else
      document.getElementById( 'admin-add-users-email' ).classList.remove( 'invalid' );

  }, [ email ]);

  return(
    <Fragment>

      {/* Loader */}
      <Loader 
        loaderID={ 'admin-add-users' }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        globalCallBack={ () => 
        {
          // update callback params
          if( typeof updateReloadTrigger === 'function' ) updateReloadTrigger();

          // close window
          if( typeof openCloseToggler === 'function' ) openCloseToggler();
        }}
      />

      {/* Add users content */}
      <div className="admin-add-users">
        {/*  Content */}
        <h4>Prénom *</h4>
        <Input 
          id='admin-add-users-first-name'
          onChanging={ e => !isNullOrUndefined( e?.detail?.value ) ? setFirstName( e.detail.value ) : void(0) }
        />
        <h4>Nom *</h4>
        <Input 
          id='admin-add-users-last-name'
          onChanging={ e => !isNullOrUndefined( e?.detail?.value ) ? setLastName( e.detail.value ) : void(0) }
        />
        <h4>Email *</h4>
        <Input 
          id='admin-add-users-email'
          onChanging={ e => !isNullOrUndefined( e?.detail?.value ) ? setEmail( e.detail.value ) : void(0) }
        />
        <h4>Fonction *</h4>
        <DropDownList 
          id='admin-add-users-role'
          dataSource={ !isNullOrUndefined( userDatas?.roles ) ? 
            roleId !== 1 ? 
              userDatas.roles.filter( role => role.id !== 1 ).map( elem => ({ label: elem.label, value: elem.label }))
              : userDatas.roles.map( elem => ({ label: elem.label, value: elem.label }))
            : [] 
          }
          selectedValues={[ role ]}
          onChange={ e => !isNullOrUndefined( e?.detail?.value ) ? setRole( e.detail.value ) : void(0) }
        />
        <p className='asterisque'>* Champs obligatoires</p>
        <div className='confirm-container' id='admin-add-users-confirm-container'>
          <Button 
            className='flat empty'            
            onClick={ () => typeof openCloseToggler === 'function' ? openCloseToggler() : null }
          >Annuler</Button>

          <Button 
            className='flat fill' 
            onClick={ addUser }
          >Ajouter</Button>
        </div>
      </div>
    </Fragment>
  )
}

export default AdminMenuContextualAddUsers;