/** Dependencies */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

/** Components */
import AdminMenu from './../../components/Menus/AdminMenu/AdminMenu';
import AdminContentKeywords from './../../components/AdminContent/Keywords/Keywords';
import AdminContentCategories from './../../components/AdminContent/Categories/Categories';
import AdminContentUsers from './../../components/AdminContent/Users/Users';

/** Helpers */
import { isNullOrUndefined } from './../../helpers/functions';
import { getInstanceDatas } from './../../helpers/instance';

/** SCSS */
import './Admin.scss';

function Admin( props )
{
  const {
    context
  } = props;

  /** Instance useNavigate Hook */
  const navigate = useNavigate();

  /** Get instance infos from url */
  const { clientID } = useParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Get instance datas from user datas */
  const instanceDatas = getInstanceDatas( clientID, userDatas );

  /** Init useState Hook */
  const [ params, setParams ] = useState( null );
  const [ reloadTrigger, setReloadTrigger ] = useState( null );

  /** Get role */
  const roleId = userDatas.enabled_instances.find( elem => elem.nickname === clientID ).roleId

  /** Redirect to / if not Admin or Super Admin user */
  useEffect( () => roleId !== 1 && roleId !== 2 ? navigate( '/' ) : void( 0 ), [] );

  return(
    <div className='admin'>
      <div className='admin-box1'>
        <div className='identity'>
          { /* Picture */
            !isNullOrUndefined( instanceDatas?.picture ) && !isNullOrUndefined( instanceDatas?.label ) ? 
              <img src={ instanceDatas.picture } alt={ instanceDatas.label } title={ instanceDatas.label } className="picture" />
              : null
          }
          
          { /* Instance Label */
            !isNullOrUndefined( instanceDatas?.label ) ? 
              <h2>{ instanceDatas.label }</h2>
              : null
          }
        </div>
      </div>
      <div className='admin-box2'>
        <AdminMenu
          context={ context }
          params={ params }
          updateReloadTrigger={ () => setReloadTrigger( Math.ceil( Math.random() * 100 ) ) }
        />
      </div>
      <div className='admin-box3'>
        {
          context === 'keywords' ? 
            <AdminContentKeywords
              callBackFct={ results => setParams( results ) }
              reloadTrigger={ reloadTrigger }
            />
          : context === 'users' ?
            <AdminContentUsers
              callBackFct={ results => setParams( results ) }
              reloadTrigger={ reloadTrigger }
            />
          : context === 'categories' ? 
            <AdminContentCategories />
          : void(0)
        }
      </div>
    </div>
  )
}

export default Admin;