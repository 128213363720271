/** Dependencies */
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { onToggler } from './../../reducers/togglers';

/** Helpers */
import { getPicto } from "./../../helpers/pictos";

/** SCSS */
import './FilterRemoverPictoMulti.scss';

function FilterRemoverPictoMulti( props )
{
  const{ 
    label,
    field,
    value,
    filterName,
    callBackFunction
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();
  
  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

  // Define increase and decrease colors
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-blue').trim();

  return(
    <div 
      className="filter picto-multi"
      id={ 'filter-' + filterName + '-' + field }
    >
      <div 
        className="label" 
        title={ label }
        onClick={ !togglers[ 'filters-windowbutton' ] ? () => dispatch( onToggler( 'filters-windowbutton' ) ) : null }
      >{ label }</div>

      { 
        value.values !== null 
        && Array.isArray( value.values ) 
        && value.values.length > 0 ?
          <div className="pictos">
            {
              value.values.map( ( values, index ) => 
                <div 
                  key={ index }
                  className="remove-button" 
                  title={ values.label }
                >
                  { getPicto( values.pictoLabel, { size: '1rem', fill: blueColor } ) }
                  <button 
                    onClick={ () => callBackFunction( filterName, field, value, values.value ) }
                  >{ getPicto( 'X', { size: '1rem', fill: blueColor, weight: 'bold' } ) }</button>
                </div>          
              )
            }
          </div>
          : null
      }

    </div>
  )
}

export default FilterRemoverPictoMulti;