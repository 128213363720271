/** Dependencies */
import { useState, useEffect, useRef } from 'react';
import { DropDownList } from 'smart-webcomponents-react/dropdownlist';

/** SCSS */
import './DropDownMenu.scss';

/** Helpers */
import { deepEqual, isNullOrUndefined } from './../../helpers/functions';

function DropDownMenu( props )
{
  const {
    id,
    label,
    dropDownMenuValues,
    dropDownMenuSelectionMode,
    grouped,
    initCurrentValues,
    callBackFunction
  } = props;

  /** Refs */
  const dropDownListRef = useRef( null );

  /** Init State */
  const [ currentValues, setCurrentValues ] = useState( null );
  const [ selectedValues, setSelectedValues ] = useState( 
    initCurrentValues !== null ? 
      { selectedValues: [ initCurrentValues ] } 
      : dropDownMenuSelectionMode === 'one' ? 
        { selectedValues: [ dropDownMenuValues[0].value ] } 
        : null 
  );

  const handleDropDownMenuChange = e => 
  {
    setSelectedValues( { selectedValues: dropDownListRef.current.selectedValues } );

    if( e.detail.value !== null )
      setCurrentValues( e.detail.value );
  }

  useEffect( () => 
  {
    // send current values to parent
    if(
      typeof callBackFunction === 'function' 
      && !isNullOrUndefined( currentValues )
      && !deepEqual( currentValues, initCurrentValues )
    )
      callBackFunction( currentValues );

  }, [ JSON.stringify( currentValues ) ]);

  return(
    <DropDownList
      ref={ dropDownListRef }
      id={ id }
      dataSource={ dropDownMenuValues }
      placeholder={ label }
      selectionMode={ dropDownMenuSelectionMode ? dropDownMenuSelectionMode : 'zeroAndOne'}
      grouped={ grouped ? grouped : false }
      dropDownMinHeight={ 150 }
      { ...selectedValues }
      onChange={ e => handleDropDownMenuChange( e ) }
    ></DropDownList>
  )
}

export default DropDownMenu;