/** Dependencies */
import { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button } from 'smart-webcomponents-react/button';

/** Components */
import ChartDisplayer from './../../components/ChartDisplayer/ChartDisplayer';
import InstanceInfos from './../../components/Instances/InstanceInfos';
import PeriodSelector from './../../components/PeriodSelector/PeriodSelector';
import PeriodCalendar from './../../components/PeriodCalendar/PeriodCalendar';
import RangeGranularity from './../../components/RangeGranularity/RangeGranularity';
import GranularitySelector from './../../components/GranularitySelector/GranularitySelector.js';
import GridCoreKeywords from './../../components/Grid/Core/Keywords/GridCoreKeywords';
import FiltersWindow from './../../components/FiltersWindow/FiltersWindow';
import KeywordsGroupsWindow from './../../components/KeywordsGroupsWindow/KeywordsGroupsWindow';
import WindowButton from './../../components/WindowButton/WindowButton';
import DisplayModeSelector from './../../components/DisplayModeSelector/DisplayModeSelector';
import FiltersRemovers from './../../components/FiltersRemovers/FiltersRemovers';
import ActionMenu from './../../components/Menus/ActionMenu/ActionMenu.js';

/** Helpers */
import { getPicto } from './../../helpers/pictos';
import { getInstanceDatas } from './../../helpers/instance';
import { 
  sortArrayByObjectField, 
  isNullOrUndefined ,
  isValidJSON
} from './../../helpers/functions';

/** Services */
import { getItem } from './../../services/LocaleStorage.js';

/** SCSS */
import '@phosphor-icons/web/regular';
import './InstanceKeywords.scss';

function InstanceKeywords()
{
  /** Instance useNavigate */
  const navigate = useNavigate();

  /** Init State */
  const [ currentPeriod, setCurrentPeriod ] = useState( null );
  const [ comparePeriod, setComparePeriod ] = useState( null );
  const [ filters, setFilters ] = useState( null );
  const [ periodGranularity, setPeriodGranularity ] = useState( null );
  const [ gridParams, setGridParams ] = useState( null );
  const [ gridComponentLoading, setGridComponentLoading ] = useState( true );
  const [ gridKeywords, setGridKeywords ] = useState( 'allKeywords' );
  const [ gridSelectedKeywords, setGridSelectedKeywords ] = useState( [] );
  const [ gridSelectedKeywordsGroups, setGridSelectedKeywordsGroups ] = useState( [] );
  const [ chartSelectedKeywords, setChartSelectedKeywords ] = useState( [] );
  const [ gridLabels, setGridLabels ] = useState( [] );
  const [ gridKeywordsGroups, setGridKeywordsGroups ] = useState( [] );
  const [ gridRankedUrls, setGridRankedUrls ] = useState( [] );  
  const [ gridExpectedUrls, setGridExpectedUrls ] = useState( [] );  
  const [ minMaxValues, setMinMaxValues ] = useState( null );
  const [ displayMode, setDisplayMode ] = useState( 'chart-grid' );
  const [ box2Width, setBox2Width ] = useState( null );
  const [ reloadTrigger, setReloadTrigger ] = useState( null );

  /** Define Refs */
  const box2Ref = useRef( null );
  const box4Ref = useRef( null );

  /** UseSearchParams Hook */ 
  const [ searchParams ] = useSearchParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();
  
  /** Get instance infos from url */
  const { clientID, device, location } = useParams();

  /** Get user id */
  const userId = getItem( 'userId' );

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  /** Redirect to first instance if not defined */
  useEffect( () => 
  {
    if( clientID === undefined )
    {
      // get nickname of first instance after sort by label
      const firstNickname = sortArrayByObjectField( structuredClone( userDatas.enabled_instances ), 'label' )[0].nickname;

      // get first instance datas
      const firstInstanceDatas =  getInstanceDatas( firstNickname, userDatas );

      // set redirect url
      let url = '/keywords/' + firstNickname;
      url += '/' + firstInstanceDatas.devLoc[0].countryValue;
      url += '/' + firstInstanceDatas.devLoc[0].deviceValue;

      // redirect to url
      navigate( url );
    }
    
  }, [] );

  /** Update grid datas after loading */
  useEffect( () =>
  { 
    if( !isNullOrUndefined( gridParams?.gridComponentLoading ) ) setGridComponentLoading( gridParams.gridComponentLoading );
    if( !isNullOrUndefined( gridParams?.keywords ) ) setGridKeywords( gridParams.keywords );
    if( !isNullOrUndefined( gridParams?.selectedKeywords ) ) setGridSelectedKeywords( gridParams.selectedKeywords );
    if( !isNullOrUndefined( gridParams?.labels ) ) setGridLabels( gridParams.labels );
    if( !isNullOrUndefined( gridParams?.keywordsGroups ) ) setGridKeywordsGroups( gridParams.keywordsGroups );
    if( !isNullOrUndefined( gridParams?.rankedUrls ) ) setGridRankedUrls( gridParams.rankedUrls );
    if( !isNullOrUndefined( gridParams?.expectedUrls ) ) setGridExpectedUrls( gridParams.expectedUrls );
    if( !isNullOrUndefined( gridParams?.minMaxValues ) ) setMinMaxValues( gridParams.minMaxValues );
    
  }, [ gridParams ] );

  /** Set url search params */
  useEffect( () => 
  {
    if( !isNullOrUndefined( searchParams.get( 'currentPeriod' ) ) )
      setCurrentPeriod( searchParams.get( 'currentPeriod' ) );
    else
      setCurrentPeriod( '12M' );

    if( !isNullOrUndefined( searchParams.get( 'comparePeriod' ) ) )
      setComparePeriod( searchParams.get( 'comparePeriod' ) );
    else
      setComparePeriod( 'N-1' );

    if( !isNullOrUndefined( searchParams.get( 'periodGranularity' ) ) )
      setPeriodGranularity( searchParams.get( 'periodGranularity' ) );
    else if( !isNullOrUndefined( searchParams.get( 'currentPeriod' ) ) )
      setPeriodGranularity( searchParams.get( 'currentPeriod' ).includes( 'S' ) ? 'week' : 'month' );
    else
      setPeriodGranularity( 'month' );

    if( 
      !isNullOrUndefined( searchParams.get( 'filters' ) ) 
      && isValidJSON( searchParams.get( 'filters' ) ) 
    )
      setFilters( JSON.parse( searchParams.get( 'filters' ) ) );
    else
      setFilters( null );
      
  }, [ searchParams ]);

  /** Add event listener on resize screen */
  useEffect( () => 
  {
    if( !isNullOrUndefined( box2Ref?.current ) )
    {
      const updateFiltersWindowWidth = () => setBox2Width( box2Ref.current.offsetWidth );

      // init width
      updateFiltersWindowWidth();

      // add event listener on screen resize
      window.addEventListener( 'resize', updateFiltersWindowWidth );
      return () => {
        window.removeEventListener( 'resize', updateFiltersWindowWidth );
      };
    }

  }, [ 
    !isNullOrUndefined( box2Ref?.current ) ? box2Ref.current.offsetWidth : void( 0 )
  ]);

  /** Add event listener on scroll for grid header */
  useEffect( () => 
  {
    if( displayMode !== 'full-chart' )
    {
      const updateGridStyle = () => 
      {
        const scrollLimitY = displayMode === 'chart-grid' ? 300 : 0;

        if( !isNullOrUndefined( document.querySelector( '.smart-grid-column-header' ) ) )
        {
          document.querySelector( '.instance-keywords' ).style.gridTemplateRows =  '72px 72px ' + scrollLimitY + 'px auto';

          if( window.scrollY > scrollLimitY )
          {            
            document.querySelector( '.smart-grid-column-header' ).style.position = 'sticky';
            document.querySelector( '.smart-grid-column-header' ).style.top = ( window.scrollY - ( scrollLimitY + 1 ) ) + 'px';
          } else {
            document.querySelector( '.smart-grid-column-header' ).style.position = 'relative';
            document.querySelector( '.smart-grid-column-header' ).style.top = 'inherit';
          }
        }
      } 

      // add event listener on screen resize
      window.addEventListener( 'scroll', updateGridStyle );
      return () => {
        window.removeEventListener( 'scroll', updateGridStyle );
      };
    }

  }, [ displayMode ]);

  /** Reset grid selected keywords groups when keyword is selected manualy */
  useEffect( () => 
  {
    if( gridSelectedKeywords.length > 0 )
      setGridSelectedKeywordsGroups( [] );
    
    else if( chartSelectedKeywords.length > 0 )
      setChartSelectedKeywords( [] );

  }, [ gridSelectedKeywords ]);

  /** Update top position of filters window if screen height < 960px */
  useEffect(() => 
  {
    if( window.screen.height <= 960 )
    {
      /** Update current scrollY position */
      const updateFilterwindowTopPosition = () => 
        window.scrollY > 0 && window.scrollY < 16 ? 
          document.getElementById('filters-window').style.setProperty( 'top', '-' + window.scrollY + 'px', 'important' )
        : window.scrollY >= 16 ?
          document.getElementById('filters-window').style.setProperty( 'top', '-16px', 'important' )
        : document.getElementById('filters-window').style.setProperty( 'top', '56px', 'important' )
  
      // add event listener on scroll to save current scrollY
      window.addEventListener( 'scroll', updateFilterwindowTopPosition );
  
      return () => {
        window.removeEventListener( 'scroll', updateFilterwindowTopPosition );
      };
    }

  }, []);

  return(
    <div className = { displayMode !== 'chart-grid' ? 'instance-keywords ' + displayMode : 'instance-keywords' }>
      { clientID !== undefined ?
        <Fragment>

          <div className='box1'>
            <InstanceInfos />

            <div className='period-infos-wrapper'>
              <PeriodSelector />
              <PeriodCalendar />
            </div>
          </div>

          <div className='box2' ref={ box2Ref }>
            <div className='header'>            
              <div className='header-left-container'>
                <h3>Mots-clés</h3>
                  <DisplayModeSelector
                    callBackFunction = { setDisplayMode }
                    currentValue = { displayMode }
                  /> 
              </div>
              <div className='header-right-container'>

                {/* Filters removers */}
                <FiltersRemovers />
                
                {/* Filters / Keywords groups => Button */}

                {/* Filters button */}
                <WindowButton 
                  id="filters"
                  className="filters"
                  activeEffect="horizontalFlip"
                  buttonContent={ getPicto( 'Funnel', { size: '1.5rem', fill: grey2Color } ) }
                  windowContent={ 
                    <FiltersWindow 
                      minMaxValues={ minMaxValues } 
                      autoCompletedLabelsValues={ gridLabels } 
                      autoCompletedRankedUrlsValues={ gridRankedUrls }
                      autoCompletedExpectedUrlsValues={ gridExpectedUrls }
                      dropDownMenuKeywordsGroups={ gridKeywordsGroups } 
                      filtersToDisplay={[ 
                        'keywords', 
                        'ranked-url', 
                        'expected-url', 
                        'clicks', 
                        'position', 
                        'impressions', 
                        'volume', 
                        'ctr', 
                        'ranked-rate', 
                        'categories', 
                        'snippets', 
                        'status' 
                      ]}
                    /> 
                  }
                  indicator={ filters !== null ? 
                    filters.map( filter => Object.keys( filter.values ).length ).reduce( ( a, v ) => a + v, 0 ) 
                    : null 
                  }
                  width={ box2Width }
                />

                {/* Keywords groups button  */}
                <WindowButton 
                  id="keywords-groups"
                  className="keywords-groups"
                  activeEffect="horizontalFlip"
                  buttonContent={ getPicto( 'Bookmarks', { size: '1.5rem', fill: grey2Color } ) }
                  windowContent={ 
                    <KeywordsGroupsWindow 
                      currentSelectedKeywords={ gridSelectedKeywords }
                      callBackFunction={ values => setGridSelectedKeywordsGroups( values ) }
                    />
                  }                        
                />
                
                {/* <Button className='flat circle'>{ getPicto( 'FloppyDisk', { size: '1.2rem' } ) }</Button>              
                <Button className='flat circle'>{ getPicto( 'DotsThreeVertical', { size: '1.6rem', weight: 'bold' } ) }</Button> */}
                
              </div>
            </div>

            {/* Mass action button */}
            <WindowButton 
              id="mass-action"
              buttonClassName="flat fill mass-action"
              buttonContent={ 
                <Fragment>
                  { getPicto( 'ArrowPlainDown', { size: '1.5rem', fill: 'white' } ) }
                  <p className='checkboxes-sum'>{ gridSelectedKeywords.length }</p>
                </Fragment>
              }
              windowContent={ 
                <ActionMenu 
                  labels={ gridSelectedKeywords }
                  loadingParams={{
                    clientID: clientID,
                    currentPeriod: currentPeriod,
                    location: location + '|' + city,
                    device: device
                  }}
                  displayActions={[ 'status', 'expectedUrl' ]}
                  forceAreYouSure
                  reloadTriggerCallBackFct={ () => setReloadTrigger( Math.ceil( Math.random() * 1000 ) ) }
                />
              }    
              disabled={ gridSelectedKeywords.length === 0 ? true : false }
            />
            
          </div>
          
          <div className='box3'>   
            
            {/* box3 header */}
            <div className='header'>
              
              {/* left col header */}
              <div className='left-col'>
                <GranularitySelector 
                  currentPeriodGranularity={ periodGranularity } 
                  resetSearchParamOnclick
                /> 
              </div>

              {/* range granularity header */}
              <RangeGranularity />

              {/* right col */}
              <div className='right-col'>
                <Button 
                  disabled={ 
                    ( 
                      gridSelectedKeywords.length === chartSelectedKeywords.length 
                      && gridSelectedKeywords.filter( elem => !chartSelectedKeywords.includes( elem ) ).length === 0 ? 
                        true : false
                    )
                  }
                  className='flat circle'
                  onClick={ () => gridSelectedKeywords.length > 0 ? setChartSelectedKeywords( gridSelectedKeywords ) : setChartSelectedKeywords( [] ) }
                >{ getPicto( 'ArrowsClockwise', { size: '1.6rem', color: grey2Color } ) }</Button>                  
              </div>           
            </div>
            
            {/* Chart Displayer */}
            <ChartDisplayer 
              loadingParams={{
                clientID: clientID,
                device: device,
                location: location + '|' + city,
                currentPeriod: currentPeriod,
                comparePeriod: comparePeriod,
                keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                gridComponentLoading: gridComponentLoading
              }}
              periodGranularity={ periodGranularity }
            />      
                  
          </div>

          <div className='box4' ref={ box4Ref }> 
            {
              !isNullOrUndefined( currentPeriod )
              && !isNullOrUndefined( comparePeriod ) ?
                <GridCoreKeywords               
                  displayMode={ displayMode }
                  loadingParams={{
                    clientID: clientID,
                    device: device,
                    location: location + '|' + city,
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    userId: userId,
                    filters: filters
                  }}
                  gridSelectedKeywordsGroups={ gridSelectedKeywordsGroups }
                  callBackFunction={ setGridParams }
                  reloadTriggerCallBackFct={ () => setReloadTrigger( Math.ceil( Math.random() * 1000 ) ) }
                  reloadTrigger={ reloadTrigger }
                />
                : null  
            }
          </div>

        </Fragment>
        : null 
      }
    </div>
  )  
}

export default InstanceKeywords;