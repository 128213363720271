/** Dependencies */
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";

/** Components */
import LoginInfos from './components/LoginInfos/LoginInfos';
import MainMenu from './components/Menus/MainMenu/MainMenu';
import BurgerMenu from './components/Menus/BurgerMenu/BurgerMenu';
import Login from './pages/Login/Login';

/** SCSS */
import './App.scss';

/** Images */
import EmpirikLogo from './assets/img/Logos-Empirik.svg';

/** Smart Element Licence Key */
window.Smart.License = 'DEBCE491-9D1F-42D9-9529-671710B5FE06';

function App()
{
  /** Get state from redux store **/
	const togglers = useSelector( state => state.togglers.value );

  return (
    <Fragment>

      {/* Header */}
      { togglers?.isAuthenticated === undefined || togglers.isAuthenticated === false ? 
        <header>
          <img src={ EmpirikLogo } alt="Empirik - Impakkt" title="Empirik - Impakkt" className='logo' />
          <LoginInfos />
        </header>
        : null
      }

      {/* Content */}
      <div className='content'>
        
        {/* Aside menu */}        
        { togglers?.isAuthenticated !== undefined && togglers.isAuthenticated === true ? 
            <aside>
              <BurgerMenu />
              <MainMenu />
              <LoginInfos className="authenticated" />
            </aside>
          : 
            <aside>&nbsp;</aside>
        }      

        {/* Page content */}
        { togglers?.isAuthenticated !== undefined && togglers.isAuthenticated === true ? 
            <section className='wrapper-pages'>
              <Outlet />
            </section>
          : 
            <section>
              <Login />  
            </section>
        }
      </div>

    </Fragment>
  );
}

export default App;
