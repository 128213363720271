/** Dependencies */
import { useState, useEffect } from 'react';

/** Components */
import DropDownMenu from './../../Filters/DropDownMenu';
import DropDownMenuMulti from './../../Filters/DropDownMenuMulti';

/** Helpers */
import { deepEqual, isNullOrUndefined } from './../../../helpers/functions';

/** SCSS */
import './Snippets.scss';

function Snippets( props )
{
  const {
    id,
    pictoTitle,
    filterLabel,
    initFilterValues,
    callBackFunction
  } = props;

  /** Init State */
  const [ filterValues, setFilterValues ] = useState( initFilterValues !== null ? initFilterValues : null );

  /** Update Range filter values */
  useEffect( () => 
  {
    // callback function if filter values not null
    if(
      !isNullOrUndefined( filterValues )
      && typeof callBackFunction === 'function' 
      && !deepEqual( filterValues, initFilterValues )
    )
      callBackFunction( filterValues );

  }, [ JSON.stringify( filterValues ) ] );

  return(
    <div className='filter-container snippets'>

      {/* Title */}
      <h4 className='picto-title'>
        { pictoTitle }
        { filterLabel }
      </h4>

      {/* Filter type */}
      <div className='filters'>

        {/* Drop Down Menu */}
        <DropDownMenu 
          id={ id + '-drop-down-menu' }
          label='Présence'
          dropDownMenuValues={[ 
            { label: 'On SERP', value: 'onSerp' },
            { label: 'Not on SERP', value: 'notOnSerp' },
            { label: 'Where target ranks', value: 'targetRank' },
            { label: 'Where target doesn\'t rank', value: 'targetNotRank' }
          ]}
          initCurrentValues={ !isNullOrUndefined( initFilterValues?.value?.filterOn ) ? initFilterValues.value.filterOn : null }
          callBackFunction={ value => setFilterValues({ 
            ...filterValues, 
            value: {
              filterOn: value.length > 0 && value === null ? 'onSerp' : value,
              values: !isNullOrUndefined( filterValues?.value?.values ) ? filterValues.value.values : []
            }
          })}
        />

        {/* Drop Down Menu */}
        <DropDownMenuMulti
          id={ id + '-drop-down-menu-multi' }
          label='Snippets'
          filterable
          dropDownMenuValues={[ 
            { label: "SEO", value: "SEO", pictoLabel: "SEO" },
            { label: "Shopping", value: "Shopping", pictoLabel: "Shopping" },
            { label: "PAA", value: "PAA", pictoLabel: "PAA" },
            { label: "Popular Products", value: "Popular Products", pictoLabel: "Popular Products" },
            { label: "Images", value: "Images", pictoLabel: "Images" },
            { label: "Ads", value: "Ads", pictoLabel: "Ads" },
            { label: "People Also Search", value: "People Also Search", pictoLabel: "People Also Search" },
            { label: "Videos", value: "Videos", pictoLabel: "Videos" },
            { label: "Knowledge Panel", value: "Knowledge Panel", pictoLabel: "Knowledge Panel" },
            { label: "Twitter", value: "Twitter", pictoLabel: "Twitter" },
            { label: "Find Results On", value: "Find Results On", pictoLabel: "Find Results On" },
            { label: "Local Results", value: "Local Results", pictoLabel: "Local Results" },
            { label: "Recipes", value: "Recipes", pictoLabel: "Recipes" },
            { label: "Top Stories", value: "Top Stories", pictoLabel: "Top Stories" },
            { label: "Map", value: "Map", pictoLabel: "Map" },
            { label: "Top Sights", value: "Top Sights", pictoLabel: "Top Sights" },
            { label: "Featured Snippet", value: "Featured Snippet", pictoLabel: "Featured Snippet" },
            { label: "Scholar", value: "Scholar", pictoLabel: "Scholar" },
            { label: "Answer Box", value: "Answer Box", pictoLabel: "Answer Box" },
            { label: "Carousel", value: "Carousel", pictoLabel: "Carousel" },
            { label: "Local Services", value: "Local Services", pictoLabel: "Local Services" },
            { label: "Jobs", value: "Jobs", pictoLabel: "Jobs" },
            { label: "Questions and Answers", value: "Questions and Answers", pictoLabel: "Questions and Answers" },
            { label: "Hotels Results", value: "Hotels Results", pictoLabel: "Hotels Results" },
            { label: "Visual Stories", value: "Visual Stories", pictoLabel: "Visual Stories" },
            { label: "Events", value: "Events", pictoLabel: "Events" },
            { label: "Google Flights", value: "Google Flights", pictoLabel: "Google Flights" }
          ]}
          initCurrentValues={ !isNullOrUndefined( initFilterValues?.value?.values ) ? initFilterValues.value.values : null }
          callBackFunction={ values => setFilterValues({ 
            ...filterValues, 
            value: {
              filterOn: !isNullOrUndefined( filterValues?.value?.filterOn ) ? filterValues.value.filterOn : 'onSerp',
              values: values
            }
          })}
        />
      </div>

    </div>
  );
}

export default Snippets;